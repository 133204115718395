import React, { useState, useEffect } from 'react';
import { urls } from './config.dev';
import { toast } from 'react-toastify';

function FirstTrimesterModal({ patient, onClose, clinicDetails, token, totalRadiologyExams }) {
  const [isIntrauterine, setIsIntrauterine] = useState('');
  const [meanSacDiameter, setMeanSacDiameter] = useState('');
  const [fetalPole, setFetalPole] = useState('');
  const [fetalNode, setFetalNode] = useState('');
  const [isSacRegular, setIsSacRegular] = useState(false);
  const [isAmnioticFluidAdequate, setIsAmnioticFluidAdequate] = useState(false);
  const [periGestationalFluid, setPeriGestationalFluid] = useState('');
  const [myometrialMasses, setMyometrialMasses] = useState('');
  const [adnexalAbnormalities, setAdnexalAbnormalities] = useState('');
  const [freeFluidCollection, setFreeFluidCollection] = useState('');
  const [fluidType, setFluidType] = useState('');
  const [bladderAppearance, setBladderAppearance] = useState('');
  const [expectedDeliveryDate, setExpectedDeliveryDate] = useState('');
  const [additionalInfo, setAdditionalInfo] = useState(''); 
  const [loading, setLoading] = useState(false);
  const [report, setReport] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [printLoading, setPrintLoading] = useState(false);
  const [gestationalWeeks, setGestationalWeeks] = useState(''); // New state for weeks
  const [gestationalDays, setGestationalDays] = useState(''); 
 

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
  
    // Defaulting fields to "Not provided" if they are not filled
    const payload = {
      isIntrauterine: isIntrauterine || 'Not provided',
      meanSacDiameter: meanSacDiameter || 'Not provided',
      fetalPole: fetalPole === 'Yes' ? 'Fetal pole seen inside the sac' : 'No fetal pole seen',
      fetalNode: fetalNode === 'Yes' ? 'Fetal node seen inside the sac' : 'No fetal node seen',
      isSacRegular: isSacRegular ? 'The gestational sac is regular' : 'The gestational sac is irregular',
      isAmnioticFluidAdequate: isAmnioticFluidAdequate ? 'Amniotic fluid volume is adequate for age' : 'Amniotic fluid volume is inadequate for age',
      periGestationalFluid: periGestationalFluid || 'Not provided',
      myometrialMasses: myometrialMasses || 'Not provided',
      gestationalAge: `${gestationalWeeks || 'Not provided'} weeks and ${gestationalDays || 'Not provided'} days`,
      adnexalAbnormalities: adnexalAbnormalities || 'Not provided',
      freeFluidCollection: freeFluidCollection || 'Not provided',
      fluidType: freeFluidCollection === 'Yes' ? (fluidType === 'Physiological' ? 'Physiological' : 'Pathological') : 'Not provided',
      bladderAppearance: bladderAppearance || 'Not provided',
      expectedDeliveryDate: expectedDeliveryDate || 'Not provided',
      additionalInfo: additionalInfo || 'Not provided', // Include additional information in the payload
    };
  
    try {
      const response = await fetch(urls.reportEarlyPregnancy, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });
  
      const data = await response.json();
      setReport(data.report);
    } catch (error) {
      console.error('Error generating report:', error);
    } finally {
      setLoading(false);
    }
  };
  
  const handleSubmitReport = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const requestBody = {
        file_id: patient.file_id,
        contact_id: patient.contact_id,
        results: report,
        totalRadiologyExams,
        token,
      };

      const response = await fetch(urls.submitradiologyresults, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });

      if (response.ok) {
        toast.success('Radiology results submitted successfully!');
        if (window.confirm('Do you want to print a report?')) {
          handlePrint();
        } else {
          onClose();
        }
      } else {
        throw new Error('Failed to submit radiology results');
      }
    } catch (error) {
      console.error('Error submitting radiology results:', error);
      toast.error('Error submitting radiology results');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handlePrint = () => {
    setPrintLoading(true);

    const printData = {
      clinicName: clinicDetails?.clinic_name || 'No Clinic Name Available',
      contact: clinicDetails?.owners_contact || 'No Contact Available',
      location: `${clinicDetails?.sub_county || ''}, ${clinicDetails?.district || ''}`,
      patientName: `${patient.first_name} ${patient.last_name}`,
      patientAge: patient.age,
      patientSex: patient.sex,
      radiologyTests: patient.radiology_exam || [],
      radiologyResults: report.trim().split('\n').map(result => result.trim()),
    };

    fetch(urls.pdfscan2, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(printData),
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(`Failed to generate PDF: ${response.statusText}`);
        }
        return response.blob();
      })
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        window.open(url, '_blank');
        toast.success('PDF report generated successfully!');
      })
      .catch(error => {
        console.error('PDF generation error:', error);
        toast.error('Failed to generate PDF report');
      })
      .finally(() => {
        setPrintLoading(false);
      });
  };
  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
      zIndex: 1000,
      padding: '20px',
    }}>
      <div style={{
        backgroundColor: 'white',
        width: '80%',
        height: '80%',
        borderRadius: '8px',
        boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
        overflowY: 'auto',
        padding: '20px',
      }}>
        <h1>First Trimester Report Generator (4 weeks to 7 weeks)</h1>
        
        <form onSubmit={handleSubmit}>
          <h2>Gestational Information</h2>
          
          <label style={{ color: 'black' }}>
            Is the gestational sac seen?
            <select value={isIntrauterine} onChange={(e) => setIsIntrauterine(e.target.value)}>
              <option value="">Select Answer</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </label>
  
          <label style={{ color: 'black' }}>
            Mean Sac Diameter:
            <input 
              type="text" 
              value={meanSacDiameter} 
              onChange={(e) => setMeanSacDiameter(e.target.value)} 
              required 
            />
          </label>
  
          <label style={{ color: 'black' }}>
            Is there a fetal pole inside the sac?
            <select value={fetalPole} onChange={(e) => setFetalPole(e.target.value)}>
              <option value="">Select Answer</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </label>
  
          <label style={{ color: 'black' }}>
            Is there a fetal node inside the sac?
            <select value={fetalNode} onChange={(e) => setFetalNode(e.target.value)}>
              <option value="">Select Answer</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </label>
  
          <label style={{ color: 'black' }}>
            Is the gestational sac regular?
            <select 
              value={isSacRegular ? 'Yes' : 'No'} 
              onChange={(e) => setIsSacRegular(e.target.value === 'Yes')}
            >
              <option value="not provided">Select from the options</option>
              <option value="The sac is regular">Yes</option>
              <option value="the sac is irregular, note that this poses a high risk of pregnancy failure">No</option>
            </select>
          </label>
  
          <label style={{ color: 'black' }}>
            Is the amniotic fluid volume adequate for age?
            <select 
              value={isAmnioticFluidAdequate} 
              onChange={(e) => setIsAmnioticFluidAdequate(e.target.value)}
            >
              <option value="not provided">select from the options</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </label>
  
          <label style={{ color: 'black' }}>
            Peri-gestational fluid:
            <select 
              value={periGestationalFluid} 
              onChange={(e) => setPeriGestationalFluid(e.target.value)}
            >
              <option value="Not provided">Select from options</option>
              <option value="There is pergestational fluid collection seen. This refers to an impression of subchorionic hemorrhage">Yes</option>
              <option value="No perigestational fluid collection seen">No</option>
            </select>
          </label>
  
          <label style={{ color: 'black' }}>
            Myometrial masses:
            <select 
              value={myometrialMasses} 
              onChange={(e) => setMyometrialMasses(e.target.value)}
            >
              <option value="not provided">select option</option>
              <option value="there are myometrial masses seen">Yes</option>
              <option value="No myometrial masses seen">No</option>
            </select>
          </label>
  
          <label style={{ color: 'black' }}>
            Adnexal abnormalities:
            <select 
              value={adnexalAbnormalities} 
              onChange={(e) => setAdnexalAbnormalities(e.target.value)}
            >
              <option value="not provided">select from options</option>
              <option value="Yes">Yes</option>
              <option value="No adnexial abnormalities seen">No</option>
            </select>
          </label>
  
          <label style={{ color: 'black' }}>
            Is there free fluid collection?
            <select value={freeFluidCollection} onChange={(e) => setFreeFluidCollection(e.target.value)}>
              <option value="">Select Answer</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </label>
  
          {freeFluidCollection === 'Yes' && (
            <>
              <label>
                Type of fluid:
                <select value={fluidType} onChange={(e) => setFluidType(e.target.value)}>
                  <option value="">Select Type</option>
                  <option value="Physiological fluid collection is seen, this is sometimes seen in early pregnancy especially if there are no evident signs of PID">Physiological</option>
                  <option value="Pathological fluid is seen. This confirms PID">Pathological</option>
                  <option value="Free pelvic fluid collection is seen, Further investigations may be taken to find out if it's physiological or not">Not sure</option>
                </select>
              </label>
            </>
          )}
  
          <label style={{ color: 'black' }}>
            Gestational Age (Weeks):
            <input 
              type="text" 
              value={gestationalWeeks} 
              onChange={(e) => setGestationalWeeks(e.target.value)} 
              min="0" 
            />
          </label>
          <label style={{ color: 'black' }}>
            Gestational Age (Days):
            <input 
              type="text" 
              value={gestationalDays} 
              onChange={(e) => setGestationalDays(e.target.value)} 
              min="0" 
            />
          </label>
  
          <label style={{ color: 'black' }}>
            Expected Delivery Date:
            <input 
              type="date" 
              value={expectedDeliveryDate} 
              onChange={(e) => setExpectedDeliveryDate(e.target.value)} 
            />
          </label>
  
          <label style={{ color: 'black' }}>
            Additional Information:
            <textarea
              value={additionalInfo}
              onChange={(e) => setAdditionalInfo(e.target.value)}
              placeholder="Add any additional details, if you have come across any abnormalities, describe them here or leave blank for default info"
              style={{
                width: '100%',
                height: '150px',
                fontSize: '16px',
                fontFamily: 'Arial, sans-serif',
                padding: '10px',
                boxSizing: 'border-box',
                marginBottom: '20px'
              }}
            />
          </label>
  
          <button
            type="submit"
            disabled={loading}
            style={{
              backgroundColor: 'blue',
              color: 'white',
              padding: '10px 20px',
              border: 'none',
              borderRadius: '5px',
              cursor: 'pointer',
              marginTop: '10px',
              marginLeft: '10px'
            }}
          >
            {loading ? 'Generating Report...' : 'Generate Report'}
          </button>
        </form>
  
        {loading && <p>Loading...</p>}
  
        {report && (
          <div>
            <h2>Generated Report</h2>
            <textarea 
              value={report} 
              onChange={(e) => setReport(e.target.value)} 
              rows={10} 
              style={{ 
                width: '100%', 
                fontFamily: 'Arial, sans-serif', 
                fontSize: '16px', 
                height: '200px',
                padding: '10px',
                boxSizing: 'border-box'
              }}
            />
          </div>
        )}
        <div style={{
    fontSize: '12px',           // Small font size
    color: '#555',              // A professional grey color
    marginBottom: '10px',       // Space below the footer
    textAlign: 'center',        // Center the text
    fontStyle: 'italic'         // Italicize for emphasis
  }}>
  Report generator can make mistakes, consider checking and editing this information.
</div>
        <button onClick={onClose} style={{ 
          backgroundColor: 'red', 
          color: 'white', 
          padding: '10px 20px', 
          border: 'none', 
          borderRadius: '5px', 
          cursor: 'pointer', 
          marginTop: '20px' 
        }}>
          Close
        </button>
        <button onClick={handleSubmitReport} disabled={isSubmitting} style={{
          backgroundColor: 'blue',
          color: 'white',
          padding: '10px 20px',
          border: 'none',
          borderRadius: '5px',
          cursor: 'pointer',
          marginTop: '20px'
        }}>
          {isSubmitting ? 'Submitting...' : 'Submit Report'}
        </button>
      </div>
    </div>
  );
  
}

export default FirstTrimesterModal;

import React, { useEffect, useState } from 'react';
import { urls } from './config.dev'; // Import URLs from config
import { faTrophy, faMedal } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './TopEmployee.css'; // Add appropriate CSS for styling

// Function EmployeeOfTheMonth
function EmployeeOfTheMonth({ token }) {
  const [topEmployee, setTopEmployee] = useState(null);
  const [celebration, setCelebration] = useState(false);
  const [previousMonthName, setPreviousMonthName] = useState('');
  const [loading, setLoading] = useState(true); // State to handle loading

  // Helper function to get the first and last day of the previous month
  const getPreviousMonthDates = () => {
    const today = new Date();
    const firstDayLastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
    const lastDayLastMonth = new Date(today.getFullYear(), today.getMonth(), 0); // Last day of last month

    // Get the month name
    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const previousMonth = monthNames[firstDayLastMonth.getMonth()];
    setPreviousMonthName(previousMonth); // Store the previous month name

    return {
      startDate: firstDayLastMonth.toISOString().split('T')[0], // YYYY-MM-DD format
      endDate: lastDayLastMonth.toISOString().split('T')[0],   // YYYY-MM-DD format
    };
  };

  useEffect(() => {
    const { startDate, endDate } = getPreviousMonthDates();
    const payload = {
      token: token,
      startDate: startDate,
      endDate: endDate,
      section: "Overall",
    };

    // Fetch performance data
    fetch(urls.fetchperformance2, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    })
      .then(response => response.json())
      .then(data => {
        setLoading(false); // Set loading to false after fetching data
        if (data && data.length > 0) {
          // Find employee with the highest percentage
          const topEmployeeData = data.reduce((prev, curr) => (prev.percentage > curr.percentage ? prev : curr));
          setTopEmployee(topEmployeeData);
          setCelebration(true); // Trigger celebration
        }
      })
      .catch(error => {
        setLoading(false); // Set loading to false in case of error
        console.error('Error fetching performance data:', error);
      });
  }, [token]);

  // Check if the current date is between the 1st and 7th
  const today = new Date();
  const isWithinCelebrationPeriod = today.getDate() >= 1 && today.getDate() <= 4;

  // If loading, don't show anything
  if (loading) {
    return null; // Or you could return a loading spinner/message if desired
  }

  // If it's not within the celebration period or there's no top employee data, don't show anything
  if (!isWithinCelebrationPeriod || !topEmployee) {
    return null;
  }

  return (
    <div className="top-employee-container" style={{ backgroundColor: 'rgba(255, 255, 255, 0.5)' }}>
      {celebration && (
        <div className={`celebration-banner ${loading ? '' : 'show'}`}>
          <FontAwesomeIcon icon={faTrophy} className="celebration-icon" />
          <FontAwesomeIcon icon={faMedal} className="celebration-icon" />
          <span className="employee-message" style={{ color: 'white' }}>
            🎉🎉 Employee of the Month for {previousMonthName} is <span style={{ color: 'yellow', fontWeight: 'bold', fontSize: 'larger' }}>{topEmployee.employee_name}</span>. Your efforts are appreciated. 🎉🎉
          </span>
          <FontAwesomeIcon icon={faMedal} className="celebration-icon" />
          <FontAwesomeIcon icon={faTrophy} className="celebration-icon" />
        </div>
      )}
    </div>
  );
  
  
}

export default EmployeeOfTheMonth;

import React, { useState, useEffect } from 'react';
import { urls } from './config.dev';

import { toast } from 'react-toastify'; // Ensure you have toast for notifications


function ThirdTrimesterModal({ patient, onClose, clinicDetails, token, totalRadiologyExams }) {
  const [fetalPosition, setFetalPosition] = useState('');
  const [fetalLie, setFetalLie] = useState('');
  const [gestationalAgeWeeks, setGestationalAgeWeeks] = useState(''); // State for weeks
  const [gestationalAgeDays, setGestationalAgeDays] = useState(''); // State for days
  const [expectedDeliveryDate, setExpectedDeliveryDate] = useState('');
  const [anatomicalStructuresNormal, setAnatomicalStructuresNormal] = useState(false);
  const [nuchalCord, setNuchalCord] = useState(''); // State for nuchal cord selection
  const [abnormalities, setAbnormalities] = useState(''); // State for abnormalities input
  const [fetalWeight, setFetalWeight] = useState(''); // State for fetal weight

  const [loading, setLoading] = useState(false);
  const [report, setReport] = useState(''); // State to hold the report

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [printLoading, setPrintLoading] = useState(false);

 
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
  
    // Prepare the payload with default values for missing inputs
    const payload = {
      fetalPosition: fetalPosition || "not provided",
      fetalLie: fetalLie || "not provided",
      gestationalAge: `${gestationalAgeWeeks || "not provided"} weeks and ${gestationalAgeDays || "not provided"} days`, // Format gestational age
      expectedDeliveryDate: expectedDeliveryDate || "not provided",
      anatomicalStructuresNormal: anatomicalStructuresNormal ? "Normal" : "not provided", // True/False logic
      nuchalCord: nuchalCord || "not provided",
      fetalWeight: fetalWeight || "not provided", // Include fetal weight with default fallback
      abnormalities: abnormalities || "not provided", // Abnormalities with default fallback
    };
  
    try {
      const response = await fetch(urls.generateObsReport, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });
  
      const data = await response.json();
      // Set the report data in the state
      setReport(data.report); 
  
    } catch (error) {
      console.error('Error generating report:', error);
    } finally {
      setLoading(false);
    }
  };
  
  const handleSubmitReport = async (e) => {
    e.preventDefault(); // Prevent the default form submission
    setIsSubmitting(true);
  
    try {
      // Construct the requestBody object with the report as results
      const requestBody = {
        file_id: patient.file_id,
        contact_id: patient.contact_id,
        results: report, // Use the report text directly as results
        totalRadiologyExams,
        token, // Include token in the request body
      };
  
      const response = await fetch(urls.submitradiologyresults, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });
  
      if (response.ok) {
        toast.success('Radiology results submitted successfully!');
        // Ask user if they want to print the report
        if (window.confirm('Do you want to print a report?')) {
          handlePrint();
        } else {
          onClose();
        }
      } else {
        throw new Error('Failed to submit radiology results');
      }
    } catch (error) {
      console.error('Error submitting radiology results:', error);
      toast.error('Error submitting radiology results');
    } finally {
      setIsSubmitting(false);
    }
  };
  
  const handlePrint = () => {
    setPrintLoading(true);

    // Prepare the data to be sent to the backend
    const printData = {
      clinicName: clinicDetails?.clinic_name || 'No Clinic Name Available',
      contact: clinicDetails?.owners_contact || 'No Contact Available',
      location: `${clinicDetails?.sub_county || ''}, ${clinicDetails?.district || ''}`,
      patientName: `${patient.first_name} ${patient.last_name}`,
      patientAge: patient.age,
      patientSex: patient.sex,
      radiologyTests: patient.radiology_exam || [],
      radiologyResults: report.trim().split('\n').map(result => result.trim()),
    };

    // Send data to the backend to generate the PDF
    fetch(urls.pdfscan2, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(printData),
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(`Failed to generate PDF: ${response.statusText}`);
        }
        return response.blob();
      })
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        window.open(url, '_blank'); // Open PDF in a new tab
        toast.success('PDF report generated successfully!');
      })
      .catch(error => {
        console.error('PDF generation error:', error);
        toast.error('Failed to generate PDF report');
      })
      .finally(() => {
        setPrintLoading(false);
      });
  };

  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
      zIndex: 1000,
      padding: '20px',
    }}>
      <div style={{
        backgroundColor: 'white',
        width: '80%',
        height: '80%',
        borderRadius: '8px',
        boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
        overflowY: 'auto',
        padding: '20px',
      }}>
        <h1>Third Trimester Report Generator</h1>
        
        <form onSubmit={handleSubmit}>
          <h2>Fetal Information</h2>
          
          <label style={{ color: 'black' }}>
            Fetal Position:
            <select value={fetalPosition} onChange={(e) => setFetalPosition(e.target.value)}>
              <option value="not provided">Select Position</option>
              <option value="Cephalic">Cephalic</option>
              <option value="Breech">Breech</option>
              <option value="Do not talk about presentation in the report">None</option>
            </select>
          </label>

          <label style={{ color: 'black' }}>
            Fetal Lie:
            <select value={fetalLie} onChange={(e) => setFetalLie(e.target.value)}>
              <option value="not provided">Select Lie</option>
              <option value="Longitudinal lie">Longitudinal</option>
              <option value="Oblique Lie with head at maternal right">Oblique Lie with head at maternal right</option>
              <option value="Oblique Lie with head at maternal left">Oblique Lie with head at maternal left</option>
              <option value="Transverse Lie with head at maternal right">Transverse Lie with head at maternal right</option>
              <option value="Transverse Lie with head at maternal left">Transverse Lie with head at maternal left</option>
            </select>
          </label>
          <label style={{ color: 'black' }}>
          Fetal Weight (kg):
          <input 
            type="text" 
            
            value={fetalWeight} 
            onChange={(e) => setFetalWeight(e.target.value)} 
            placeholder="Enter fetal weight in kg"
            style={{
              width: '100%',
              padding: '8px',
              fontSize: '16px',
              margin: '10px 0',
              boxSizing: 'border-box',
            }}
          />
        </label>

          <label style={{ color: 'black' }}>
            Gestational Age (weeks):
            <input 
              type="text" 
              value={gestationalAgeWeeks} 
              onChange={(e) => setGestationalAgeWeeks(e.target.value)} 
            
            />
          </label>

          <label style={{ color: 'black' }}>
            Gestational Age (days):
            <input 
              type="text" 
              value={gestationalAgeDays} 
              onChange={(e) => setGestationalAgeDays(e.target.value)} 
             
            />
          </label>

          <label style={{ color: 'black' }}>
            Expected Date of Delivery:
            <input 
              type="date" 
              value={expectedDeliveryDate} 
              onChange={(e) => setExpectedDeliveryDate(e.target.value)} 
             
            />
          </label>

          <label style={{ color: 'black' }}>
            <input 
              type="checkbox" 
              checked={anatomicalStructuresNormal} 
              onChange={(e) => setAnatomicalStructuresNormal(e.target.checked)} 
            />
            All anatomical structures of the fetus and mother are normal
          </label>

          {/* Nuchal Cord Section */}
          <label style={{ color: 'black' }}>
            Nuchal Cord:
            <select value={nuchalCord} onChange={(e) => setNuchalCord(e.target.value)}>
              <option value="">Select Nuchal Cord Status</option>
              <option value="No Nuchal Cord">No cord seen around the fetal neck</option>
              <option value="x1">A single loop of cord is seen around the fetal neck.</option>
              <option value="x2">A double loop of cord is seen around the fetal neck.</option>
            </select>
          </label>

          <label style={{ color: 'black' }}>
            Additional information:
            <textarea 
              value={abnormalities} 
              onChange={(e) => setAbnormalities(e.target.value)} 
              placeholder="Add any additional details, if you have come across any abnormalities, describe them here or leave blank for default info"
            style={{
              width: '100%',
              height: '150px',
              fontSize: '16px',
              fontFamily: 'Arial, sans-serif',
              padding: '10px',
              boxSizing: 'border-box',
              marginBottom: '20px'
            }}
          />
          </label>

       
<button
  type="submit"
  disabled={loading}
  style={{
    backgroundColor: 'blue',
    color: 'white',
    padding: '10px 20px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    marginTop: '10px',
    marginLeft: '10px'
  }}
>
  {loading ? 'Generating Report...' : 'Generate Report'}
</button>
        </form>

        {report && (
          <div className="report-display">
            <h2>Generated Report</h2>
            <textarea 
              value={report} 
              onChange={(e) => setReport(e.target.value)} 
              rows={10} 
              style={{ 
                width: '100%', 
                fontFamily: 'Arial, sans-serif', 
                fontSize: '16px', 
                height: '200px',
                padding: '10px',
                boxSizing: 'border-box'
              }}
            />
           
          </div>
        )}

<div style={{
    fontSize: '12px',           // Small font size
    color: '#555',              // A professional grey color
    marginBottom: '10px',       // Space below the footer
    textAlign: 'center',        // Center the text
    fontStyle: 'italic'         // Italicize for emphasis
  }}>
  Report generator can make mistakes, consider checking and editing this information.
</div>

<button onClick={onClose} style={{ 
          backgroundColor: 'red', 
          color: 'white', 
          padding: '10px 20px', 
          border: 'none', 
          borderRadius: '5px', 
          cursor: 'pointer', 
          marginTop: '20px' 
        }}>
          Close
        </button>

        <button onClick={handleSubmitReport} disabled={isSubmitting} style={{
          backgroundColor: 'blue',
          color: 'white',
          padding: '10px 20px',
          border: 'none',
          borderRadius: '5px',
          cursor: 'pointer',
          marginTop: '10px',
          marginLeft: '10px'
        }}>
           {isSubmitting ? 'Submitting...' : 'Submit Radiology Results'}
        </button>
      </div>
    </div>
  );
}

export default ThirdTrimesterModal;
import React, { useState, useEffect } from 'react';
import { urls } from './config.dev';
import printJS from 'print-js'; // Ensure you have installed print-js: yarn add print-js

function FPreceipt({ details, onClose }) {
  const [clinicDetails, setClinicDetails] = useState(null);
  const { patient_name, method, price, token } = details;

  // Convert price to a number
  const totalPrice = parseFloat(price);

  // Function to fetch clinic details
  useEffect(() => {
    if (token) {
      const fetchClinicDetails = async () => {
        try {
          const response = await fetch(urls.fetchclinicdetails, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ token }), // Send token in the payload
          });

          if (!response.ok) {
            throw new Error('Failed to fetch clinic details');
          }

          const data = await response.json();
          setClinicDetails(data);
        } catch (error) {
          console.error('Error fetching clinic details:', error);
        }
      };

      fetchClinicDetails();
    }
  }, [token]);

  const handlePrintReceipt = () => {
    const currentDate = new Date().toLocaleString();
    const receiptHtml = `
      <html>
      <head>
        <style>
          body {
            font-family: Arial, sans-serif;
            margin: 0;
            padding: 0;
            width: 80mm; /* Adjust to match printer width */
            height: auto;
          }
          .receipt-content {
            padding: 0;
            margin: 0;
            width: 100%;
            text-align: center;
          }
          .clinic-heading {
            font-size: 28px;
            font-weight: bold;
            margin: 0;
          }
          .clinic-details {
            font-size: 18px;
            margin: 5px 0;
          }
          .receipt-details {
            font-size: 16px;
            text-align: left;
            margin: 10px 0;
          }
          .receipt-details p {
            margin: 5px 0;
          }
          .receipt-footer {
            font-size: 14px;
            text-align: center;
            color: #555;
            margin-top: 20px;
          }
          .star-line {
            text-align: center;
            margin: 20px 0;
            font-size: 14px;
          }
        </style>
      </head>
      <body>
        <div class="receipt-content">
          <h2 class="clinic-heading">${clinicDetails?.clinic_name || 'Clinic Name'}</h2>
          <div class="clinic-details">
            <p>${clinicDetails?.district || 'District'}, ${clinicDetails?.sub_county || 'Sub-county'}</p>
            <p>Contact: ${clinicDetails?.owners_contact || 'Contact'}</p>
            <p>${currentDate}</p>
          </div>
          <div class="receipt-details">
            <p><strong>Client Name:</strong> ${patient_name}</p>
            <p><strong>Family Planning Method paid for:</strong> ${method}</p>
            <p><strong>Total Amount:</strong> UGX ${isNaN(totalPrice) ? '0.00' : totalPrice.toFixed(2)}</p>
          </div>
          <hr>
          <p>Software developed by: DeepMind E-systems 0786747733</p>
          <p>Thank you for choosing our service!</p>
        </div>
      </body>
      </html>
    `;

    printJS({
      printable: receiptHtml,
      type: 'raw-html',
      documentTitle: 'Family Planning Payment Receipt',
    });
  };

  if (!clinicDetails) return null; // Don't render if clinic details are not loaded

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h3>PAYMENT SUCCESSFUL</h3>
        <h4>Receipt Details</h4>
        <p>Name: {patient_name}</p>
        <p>Payment Method: {method}</p>
        <p>Total Amount: UGX {isNaN(totalPrice) ? '0.00' : totalPrice.toFixed(2)}</p>

        <button onClick={handlePrintReceipt}>Print Receipt</button>
        <button onClick={onClose}>Close Receipt</button>
      </div>
    </div>
  );
}

export default FPreceipt;

import React, { useState, useEffect } from 'react';

import { urls } from './config.dev';
import './NurseDashboard.css'; // Ensure you import the CSS file
import GiveFPmodal from './GiveFPmodal'; // Assuming it's in the same directory
import { useNavigate } from 'react-router-dom';
import Topbar from './Topbar'; // Import the Topbar component
import { ToastContainer, toast } from 'react-toastify'; // Import toast functions
import 'react-toastify/dist/ReactToastify.css'; // Import toast styles

function NurseDashboard() {
  const [fpPatients, setFpPatients] = useState([]); // Patients coming for family planning
  const [familyPlanningMethods, setFamilyPlanningMethods] = useState([]); // Family planning methods
  const [selectedPatient, setSelectedPatient] = useState(null); // Patient selected for family planning method
  const [clientStatuses, setClientStatuses] = useState([]); // Client statuses
  const [errorMessage, setErrorMessage] = useState('');
  const [showModal, setShowModal] = useState(false); // Track modal visibility
  const [modalData, setModalData] = useState(null); // Store selected client data
  const navigate = useNavigate();
  const [employeeName, setEmployeeName] = useState('');
  
  // Get token from the URL
  const params = new URLSearchParams(window.location.search);
  const urlToken = params.get('token');

  useEffect(() => {
    const fetchTokenAndCheckSecurity = async () => {
      try {
        const params = new URLSearchParams(window.location.search);
        const tokenFromUrl = params.get('token');
        console.log('URL Token:', tokenFromUrl);

        const securityResponse = await fetch(urls.security, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ token: tokenFromUrl }),
        });

        if (securityResponse.ok) {
          const securityData = await securityResponse.json();

          if (securityData.message === 'Session valid') {
            setEmployeeName(securityData.employee_name);
            localStorage.setItem('token', securityData.clinic_session_token);
           
          } else if (securityData.error === 'Session expired') {
            navigate(`/dashboard?token=${securityData.clinic_session_token}`);
          } else {
            navigate('/login');
          }
        } else {
          throw new Error('Failed to perform security check');
        }
      } catch (error) {
        console.error('Error performing security check:', error);
        navigate('/login');
      }
    };

    fetchTokenAndCheckSecurity();
  }, [navigate]);
  const handleViewPatientFiles = () => {
    const params = new URLSearchParams(window.location.search);
    const tokenFromUrl = params.get('token');
    navigate(`/patientfiles2/?token=${tokenFromUrl}`);
  };
  const handleGoToMaternitySection = () => {
    const params = new URLSearchParams(window.location.search);
    const tokenFromUrl = params.get('token');
    navigate(`/maternity-dashboard/?token=${tokenFromUrl}`);
  };
  // Function to fetch patients coming for family planning
   // Function to fetch patients coming for family planning
   const fetchFamilyPlanningPatients = async () => {
    if (!urlToken) {
      setErrorMessage('No token found. Please ensure you are logged in.');
      return;
    }

    try {
      const response = await fetch(urls.fetchFPwaiting, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token: urlToken }), // Send token in payload
      });

      if (response.ok) {
        const data = await response.json();
        
        if (data.message) {
          setErrorMessage(data.message); // Display the message
          setFpPatients([]); // Clear the patient list
        } else {
          setFpPatients(data.contacts || []); // Store fetched patients
          setErrorMessage(''); // Clear any previous error messages
        }
      } else {
        throw new Error('Failed to fetch family planning patients');
      }
    } catch (error) {
      console.error('Error fetching family planning patients:', error);
      setErrorMessage('Error fetching family planning patients.');
    }
  };

  // Function to fetch all family planning methods
  const fetchAllMethods = async () => {
    try {
      const response = await fetch(urls.allFamilyPlanningMethods, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token: urlToken }), // Send token in payload
      });

      if (response.ok) {
        const methods = await response.json();
        setFamilyPlanningMethods(methods); // Store methods with their prices
      } else {
        throw new Error('Failed to fetch family planning methods');
      }
    } catch (error) {
      setErrorMessage('Error fetching family planning methods.');
    }
  };

  // Function to fetch client statuses
const fetchClientStatuses = async () => {
  if (!urlToken) {
    setErrorMessage('No token found. Please ensure you are logged in.');
    return;
  }

  try {
    const response = await fetch(urls.fetchFPstatus, { // Use the new endpoint
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ token: urlToken }), // Send the same token in payload
    });

    if (response.ok) {
      const data = await response.json();
      
      // Check for specific "message" in response
      if (data.message) {
        setErrorMessage(data.message); // Display the message
        setClientStatuses([]); // Clear the client status list
      } else {
        setClientStatuses(data.contacts || []); // Store fetched client statuses
        setErrorMessage(''); // Clear any previous error messages
      }
    } else {
      throw new Error('Failed to fetch client statuses');
    }
  } catch (error) {
    console.error('Error fetching client statuses:', error);
    setErrorMessage('Error fetching client statuses.');
  }
};

// Add this inside the JSX of the container where you display client statuses


  // Submit selected family planning method for the patient
  const submitFamilyPlanningMethod = async (fpId, methodName) => {
    try {
      const response = await fetch(urls.payfp, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token: urlToken, fpId, methodName }), // Send token, fpId, and methodName in payload
      });

      if (response.ok) {
        toast.success(`Submission successful. Please send the client to the cashier to make payments .`);
        setSelectedPatient(null); // Clear selected patient after submission
      } else {
        throw new Error('Failed to submit family planning method');
      }
    } catch (error) {
      console.error('Error submitting family planning method:', error);
      setErrorMessage('Error submitting family planning method.');
    }
  };

  // Run on initial load
  useEffect(() => {
    fetchFamilyPlanningPatients(); // Fetch family planning patients
    fetchAllMethods(); // Fetch available methods
    fetchClientStatuses(); // Fetch client statuses

    const interval = setInterval(() => {
      fetchFamilyPlanningPatients(); // Refresh patient data every 10 seconds
      fetchClientStatuses(); // Refresh client statuses every 10 seconds
    }, 10000);

    return () => clearInterval(interval); // Clear interval on component unmount
  }, []);

  // Handle method selection prompt for a specific patient
  const handleMethodSelection = (patient) => {
    setSelectedPatient(patient); // Store the selected patient for the prompt
  };
  const handleMethodSelect = (methodName) => {
    const confirmationMessage = `You have selected ${methodName} as a family planning method for ${selectedPatient.first_name} ${selectedPatient.last_name}. Please confirm this decision`;
    if (window.confirm(confirmationMessage)) {
        // If confirmed, proceed with the submission
        submitFamilyPlanningMethod(selectedPatient.fp_id, methodName);
    }
};

  // Render patient cards for those waiting for family planning services
  const renderFpPatients = () => {
    if (fpPatients.length === 0) {
      return <p>No patients found.</p>;
    }
   
 
    return fpPatients.map((patient) => (
      <div className="fp-patient-card" key={patient.fp_id}>
        <h4>{patient.first_name} {patient.last_name}</h4>
        <p>Age: {patient.age}</p>
        <p>Sex: {patient.sex}</p>
        <p>Phone: {patient.phone_number}</p>
        <button onClick={() => handleMethodSelection(patient)}>
          Select Family Planning Method
        </button>
      </div>
    ));
  };

  // Render method selection prompt
  const renderMethodSelection = () => {
    if (!selectedPatient) return null;

    return (
      <div className="fp-method-selection-prompt">
      <button onClick={() => setSelectedPatient(null)} className="close-button">X</button> {/* Close Button */}
      <h4>Select Family Planning Method for {selectedPatient.first_name} {selectedPatient.last_name}</h4>
      <ul>
          {familyPlanningMethods.map((method, index) => (
              <li key={index} onClick={() => handleMethodSelect(method.name)}>
                  {method.name} - UGX {method.price}
              </li>
          ))}
      </ul>
        </div>
    );
};
   // Ensure this function is defined
   const handleOpenModal = (client) => {
    setModalData({ ...client, token: urlToken });
    setShowModal(true);
  }
  const renderClientStatuses = () => {
    if (clientStatuses.length === 0) {
      return <p className="no-status-message">No clients available for family planning services.</p>;
    }
  // Render client status cards
  return clientStatuses.map((client) => (
    <div className="fp-status-card" key={client.contact_id}>
      <h4>{client.first_name} {client.last_name}</h4>
      <p>Age: {client.age}</p>
      <p>Sex: {client.sex}</p>
      <p>Status: 
        {client.status.includes('Give') ? (
          <span className="clickable-status" onClick={() => handleOpenModal(client)}>
            {client.status} {/* Make it clickable if "Give" is in the status */}
          </span>
        ) : (
          client.status
        )}
      </p>
      <p>Method: {client.method}</p> {/* Displaying the method */}
    </div>
  ));
};
return (
  <div className={`nurse-dashboard3 ${showModal ? 'blur' : ''}`}>
     <Topbar token={urlToken} />
     <ToastContainer />
    <div className="fp-container">
      <div className="fp-patients-container" style={{ position: 'relative' }}>
      <h3 style={{ textAlign: 'center', fontSize: '1.5em', fontWeight: 'bold', marginTop: '-10px' }}>
  Family Planning Clients Coming in from Triage
</h3>

        {errorMessage ? <p style={{ marginTop: '380px' }}>{errorMessage}</p> : renderFpPatients()}

      </div>

      <div className="fp-status-container">
        <h3 className="status-heading">Recent Family Planning Clients</h3> {/* Added class for styling */}
        {renderClientStatuses()}
      </div>
    </div>

    <div className="fp-method-selection-container">
      {renderMethodSelection()}
    </div>

 

    {showModal && (
      <GiveFPmodal
        clientDetails={modalData}
        onClose={() => setShowModal(false)}
      />
    )}

    {/* Sticky Button at the bottom */}
    <button
      onClick={handleViewPatientFiles}
      style={{
        position: 'sticky',
        bottom: 0,
        left: 0,
        width: '100%',
        padding: '10px 0',
        fontSize: '1.5em',
        backgroundColor: '#024922', // Light green
        color: 'white',
        border: 'none',
        cursor: 'pointer',
      }}
    >
      View Patient Files
    </button>
    <button
  onClick={handleGoToMaternitySection}
  style={{
    position: 'sticky',
    bottom: '60px', // Adjusting bottom to ensure it stays below the first button
    left: 0,
    width: '100%',
    padding: '10px 0',
    fontSize: '1.5em',
    backgroundColor: '#024922', // Light green
    color: 'white',
    border: 'none',
    cursor: 'pointer',
  }}
>
  Maternity Section ( coming soon , dont use it yet)
</button>
  </div>
);


};

export default NurseDashboard;

import React, { useState, useEffect } from 'react';
import { urls } from './config.dev';
import { toast } from 'react-toastify'; // Ensure you have toast for notifications

function SecondTrimesterModal({ patient, onClose, clinicDetails, token, totalRadiologyExams }) {
  const [fetusIntraUterine, setFetusIntraUterine] = useState('');
  const [fetalCardiacActivity, setFetalCardiacActivity] = useState('');
  const [fetalMovements, setFetalMovements] = useState('');
  const [amnioticFluidVolume, setAmnioticFluidVolume] = useState('');
  const [fetalHeadTrunkLimbs, setFetalHeadTrunkLimbs] = useState('');
  const [perigestationalFluid, setPerigestationalFluid] = useState('');
  const [crownToRumpLength, setCrownToRumpLength] = useState('');
  const [myometrialAbnormalities, setMyometrialAbnormalities] = useState('');
  const [adnexialAbnormalities, setAdnexialAbnormalities] = useState('');
  const [pelvicFluidCollection, setPelvicFluidCollection] = useState('');
  const [gestationalWeeks, setGestationalWeeks] = useState('');
  const [gestationalDays, setGestationalDays] = useState('');
  const [expectedDeliveryDate, setExpectedDeliveryDate] = useState('');
  const [additionalInfo, setAdditionalInfo] = useState(''); // State for additional information
  const [loading, setLoading] = useState(false);
  const [report, setReport] = useState(''); // State to hold the report

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [printLoading, setPrintLoading] = useState(false);

  // Effect to log props when the component mounts
  useEffect(() => {
    console.log('Patient:', patient);
    console.log('Clinic Details:', clinicDetails);
    console.log('Token:', token);
    console.log('Total Radiology Exams:', totalRadiologyExams);
  }, [patient, clinicDetails, token, totalRadiologyExams]); // Run effect when props change

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    // Prepare the payload only with available data
    const payload = {
      fetusIntraUterine: fetusIntraUterine || 'Not provided',
      fetalCardiacActivity: fetalCardiacActivity || 'Not provided',
      fetalMovements: fetalMovements || 'Not provided',
      amnioticFluidVolume: amnioticFluidVolume || 'Not provided',
      fetalHeadTrunkLimbs: fetalHeadTrunkLimbs || 'Not provided',
      perigestationalFluid: perigestationalFluid || 'Not provided',
      crownToRumpLength: crownToRumpLength || 'Not provided',
      myometrialAbnormalities: myometrialAbnormalities || 'Not provided',
      adnexialAbnormalities: adnexialAbnormalities || 'Not provided',
      pelvicFluidCollection: pelvicFluidCollection || 'Not provided',
      gestationalAge: gestationalWeeks && gestationalDays
        ? `${gestationalWeeks} weeks and ${gestationalDays} days`
        : 'Not provided',
      expectedDeliveryDate: expectedDeliveryDate || 'Not provided',
      additionalInfo: additionalInfo || 'No abnormalities noted. General instructions followed.', // Placeholder info
    };

    try {
      const response = await fetch(urls.generateObsReport2nd, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      const data = await response.json();
      setReport(data.report); // Set the report data in the state

    } catch (error) {
      console.error('Error generating report:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmitReport = async (e) => {
    e.preventDefault(); // Prevent the default form submission
    setIsSubmitting(true);

    try {
      // Construct the requestBody object with the report as results
      const requestBody = {
        file_id: patient.file_id,
        contact_id: patient.contact_id,
        results: report, // Use the report text directly as results
        totalRadiologyExams,
        token, // Include token in the request body
      };

      const response = await fetch(urls.submitradiologyresults, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });

      if (response.ok) {
        toast.success('Radiology results submitted successfully!');
        if (window.confirm('Do you want to print a report?')) {
          handlePrint();
        } else {
          onClose();
        }
      } else {
        throw new Error('Failed to submit radiology results');
      }
    } catch (error) {
      console.error('Error submitting radiology results:', error);
      toast.error('Error submitting radiology results');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handlePrint = () => {
    setPrintLoading(true);

    // Prepare the data to be sent to the backend
    const printData = {
      clinicName: clinicDetails?.clinic_name || 'No Clinic Name Available',
      contact: clinicDetails?.owners_contact || 'No Contact Available',
      location: `${clinicDetails?.sub_county || ''}, ${clinicDetails?.district || ''}`,
      patientName: `${patient.first_name} ${patient.last_name}`,
      patientAge: patient.age,
      patientSex: patient.sex,
      radiologyTests: patient.radiology_exam || [],
      radiologyResults: report.trim().split('\n').map(result => result.trim()),
    };

    // Send data to the backend to generate the PDF
    fetch(urls.pdfscan2, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(printData),
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(`Failed to generate PDF: ${response.statusText}`);
        }
        return response.blob();
      })
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        window.open(url, '_blank'); // Open PDF in a new tab
        toast.success('PDF report generated successfully!');
      })
      .catch(error => {
        console.error('PDF generation error:', error);
        toast.error('Failed to generate PDF report');
      })
      .finally(() => {
        setPrintLoading(false);
      });
  };

   return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
      zIndex: 1000,
      padding: '20px',
    }}>
      <div style={{
        backgroundColor: 'white',
        width: '80%',
        height: '80%',
        borderRadius: '8px',
        boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
        overflowY: 'auto',
        padding: '20px',
      }}>
        <h1>Late 1st Trimester and Early 2nd Trimester Report</h1>

        <form onSubmit={handleSubmit}>
          <h2>Fetal Information</h2>

          <label style={{ color: 'black' }}>
            Is the fetus intrauterine?
            <select value={fetusIntraUterine} onChange={(e) => setFetusIntraUterine(e.target.value)}>
              <option value="">Select Answer</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </label>

          <label style={{ color: 'black' }}>
            Can you see fetal cardiac activity?
            <select value={fetalCardiacActivity} onChange={(e) => setFetalCardiacActivity(e.target.value)}>
              <option value="">Select Answer</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </label>

          <label style={{ color: 'black' }}>
            Can you see any fetal movements?
            <select value={fetalMovements} onChange={(e) => setFetalMovements(e.target.value)}>
              <option value="">Select Answer</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </label>

          <label style={{ color: 'black' }}>
            Is the amniotic fluid volume adequate for age?
            <select value={amnioticFluidVolume} onChange={(e) => setAmnioticFluidVolume(e.target.value)}>
              <option value="">Select Answer</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </label>

          <label style={{ color: 'black' }}>
            Are the fetal head, trunk, and limbs present?
            <select value={fetalHeadTrunkLimbs} onChange={(e) => setFetalHeadTrunkLimbs(e.target.value)}>
              <option value="">Select Answer</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </label>

          <label style={{ color: 'black' }}>
            Is there perigestational fluid?
            <select value={perigestationalFluid} onChange={(e) => setPerigestationalFluid(e.target.value)}>
              <option value="">Select Answer</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </label>

          <label style={{ color: 'black' }}>
            Crown to rump length (mm):
            <input type="text" value={crownToRumpLength} onChange={(e) => setCrownToRumpLength(e.target.value)} />
          </label>

          <h2>Uterine and Adnexial Information</h2>

          <label style={{ color: 'black' }}>
            Any myometrial abnormalities?
            <select value={myometrialAbnormalities} onChange={(e) => setMyometrialAbnormalities(e.target.value)}>
              <option value="">Select Answer</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </label>

          <label style={{ color: 'black' }}>
            Any adnexial abnormalities?
            <select value={adnexialAbnormalities} onChange={(e) => setAdnexialAbnormalities(e.target.value)}>
              <option value="">Select Answer</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </label>

          <label style={{ color: 'black' }}>
            Any pelvic fluid collection?
            <select value={pelvicFluidCollection} onChange={(e) => setPelvicFluidCollection(e.target.value)}>
              <option value="">Select Answer</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </label>

          <label style={{ color: 'black' }}>
            Gestational Age (Weeks and Days):
            <input
              type="text"
              placeholder="Weeks"
              value={gestationalWeeks}
              onChange={(e) => setGestationalWeeks(e.target.value)}
            />
            <input
              type="text"
              placeholder="Days"
              value={gestationalDays}
              onChange={(e) => setGestationalDays(e.target.value)}
            />
          </label>

          <label style={{ color: 'black' }}>
            Expected Delivery Date:
            <input
              type="date"
              value={expectedDeliveryDate}
              onChange={(e) => setExpectedDeliveryDate(e.target.value)}
            />
          </label>

          <h2>Additional Information</h2>
          <textarea
            value={additionalInfo}
            onChange={(e) => setAdditionalInfo(e.target.value)}
            placeholder="Add any additional details, if you have come across any abnormalities, describe them here or leave blank for default info"
            style={{
              width: '100%',
              height: '150px',
              fontSize: '16px',
              fontFamily: 'Arial, sans-serif',
              padding: '10px',
              boxSizing: 'border-box',
              marginBottom: '20px'
            }}
          />

<button
  type="submit"
  disabled={loading}
  style={{
    backgroundColor: 'blue',
    color: 'white',
    padding: '10px 20px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    marginTop: '10px',
    marginLeft: '10px'
  }}
>
  {loading ? 'Generating Report...' : 'Generate Report'}
</button>

        </form>

        {report && (
          <div>
            <h2>Generated Report</h2>
            <textarea 
              value={report} 
              onChange={(e) => setReport(e.target.value)} 
              rows={10} 
              style={{ 
                width: '100%', 
                fontFamily: 'Arial, sans-serif', 
                fontSize: '16px', 
                height: '200px',
                padding: '10px',
                boxSizing: 'border-box'
              }}
            />
            
          </div>
        )}
        <div style={{
    fontSize: '12px',           // Small font size
    color: '#555',              // A professional grey color
    marginBottom: '10px',       // Space below the footer
    textAlign: 'center',        // Center the text
    fontStyle: 'italic'         // Italicize for emphasis
  }}>
  Report generator can make mistakes, consider checking and editing this information.
</div>

        <button onClick={onClose} style={{ 
          backgroundColor: 'red', 
          color: 'white', 
          padding: '10px 20px', 
          border: 'none', 
          borderRadius: '5px', 
          cursor: 'pointer', 
          marginTop: '20px' 
        }}>
          Close
        </button>

        <button onClick={handleSubmitReport} disabled={isSubmitting} style={{
          backgroundColor: 'blue',
          color: 'white',
          padding: '10px 20px',
          border: 'none',
          borderRadius: '5px',
          cursor: 'pointer',
          marginTop: '10px',
          marginLeft: '10px'
        }}>
           {isSubmitting ? 'Submitting...' : 'Submit Radiology Results'}
        </button>
      </div>
    </div>
  );
}

export default SecondTrimesterModal;
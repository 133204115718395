import React, { useEffect, useState } from 'react';
import './MedicalBillModal.css'; // Add styles for MedicalBillModal
import { urls } from './config.dev'; // Import the URLs

function MedicalBillModal({ fileId, billData, onClose, token }) {
    const [processedData, setProcessedData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [discount, setDiscount] = useState(0);
    const [confirmingBill, setConfirmingBill] = useState(false);
    const [additionalRows, setAdditionalRows] = useState([]);
    const [isEditing, setIsEditing] = useState(true); // Track editing state

    useEffect(() => {
        const fetchProcessedBillData = async () => {
            try {
                const response = await fetch(urls.calculatebill, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ fileId, billData }),
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();
                setProcessedData(data);
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchProcessedBillData();
    }, [fileId, billData]);

    const handleDiscountChange = (e) => {
        setDiscount(parseFloat(e.target.value));
    };

    const handleAddRow = () => {
        const defaultRow = { drug: '', packaging: '', unitPrice: 0, quantity: 0, totalPrice: 0 };
        setAdditionalRows([...additionalRows, { ...defaultRow }]);
    };

    const handleTotalChange = (index, e) => {
        const newRows = [...additionalRows];
        newRows[index].totalPrice = parseFloat(e.target.value) || 0;
        setAdditionalRows(newRows);
        updateTotalBill();
    };

    const updateTotalBill = () => {
        let additionalTotal = additionalRows.reduce((acc, row) => acc + row.totalPrice, 0);
        let processedTotal = processedData && Array.isArray(processedData.bill) ? processedData.bill.reduce((acc, item) => acc + item.totalPrice, 0) : 0;
        let totalBill = processedTotal + additionalTotal - discount;
        return totalBill;
    };

    const handleConfirmBill = async () => {
        const totalBill = updateTotalBill();
        if (totalBill <= 0) {
            alert("Total bill must be greater than zero. Please review the bill details.");
            return;
        }

        if (window.confirm("Make sure you and the patient have agreed on the payment. By clicking continue you confirm that the patient is willing to foot this bill.")) {
            setConfirmingBill(true);
            try {
                const response = await fetch(urls.confirmbill, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        fileId,
                        totalBill,
                        discount,
                        token
                    }),
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const responseData = await response.json();
                if (responseData.message === "Credit information updated successfully.") {
                    alert("Bill confirmed. Patient successfully added to the billing section.");
                    onClose();
                }

            } catch (error) {
                console.error(error);
            } finally {
                setConfirmingBill(false);
            }
        }
    };

    const handlePrintBill = async () => {
        const totalBill = updateTotalBill();

        // Prepare the payload
        const payload = {
            fileId,
            discount,
            totalBill,
            billItems: [
                ...processedData.bill.map(item => ({
                    drug: item.drug || "",
                    packaging: item.packaging || "",
                    unitPrice: item.unitPrice || 0,
                    quantity: item.quantity || 0,
                    totalPrice: item.totalPrice || 0
                })),
                ...additionalRows.map(row => ({
                    drug: row.drug || "",
                    packaging: row.packaging || "",
                    unitPrice: row.unitPrice || 0,
                    quantity: row.quantity || 0,
                    totalPrice: row.totalPrice || 0
                }))
            ],
            token
        };

        try {
            const response = await fetch(urls.printbill, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            });

            if (!response.ok) {
                throw new Error('Failed to generate the bill');
            }

            const blob = await response.blob(); // Handle PDF blob response

            // Create a URL for the PDF blob and open it in a new tab
            const pdfUrl = URL.createObjectURL(blob);
            window.open(pdfUrl, '_blank'); // Open PDF in a new tab

        } catch (error) {
            console.error('Error generating PDF:', error);
            alert('Failed to print the bill. Please try again.');
        }
    };

    if (loading) {
        return <div className="medical-bill-modal">Processing patient bill...</div>;
    }

    if (error) {
        return <div className="medical-bill-modal">Error: {error}</div>;
    }

    let totalBill = updateTotalBill();
    return (
        <div className="medical-bill-modal">
            <div className="medical-bill-scrollable-content">
                <span className="close-modal" onClick={onClose}>&#10005;</span>
                <h2 className="modal-heading">Medical Bill for {processedData.firstName} {processedData.lastName} (File ID: {fileId})</h2>
                {processedData && Array.isArray(processedData.bill) && (
                    <div className="medical-bill-table-container">
                        <table className="medical-bill-table">
                            <thead>
                                <tr>
                                    <th>Drug</th>
                                    <th>Packaging</th>
                                    <th>Unit Price</th>
                                    <th>Quantity</th>
                                    <th>Total Price</th>
                                </tr>
                            </thead>
                            <tbody>
                                {processedData.bill.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.drug}</td>
                                        <td>{item.packaging}</td>
                                        <td>{item.unitPrice}</td>
                                        <td>{item.quantity}</td>
                                        <td>{item.totalPrice}</td>
                                    </tr>
                                ))}
                                {additionalRows.map((row, index) => (
                                    <tr key={index + processedData.bill.length}>
                                        <td>{isEditing ? <input className="table-input" value={row.drug} onChange={(e) => {
                                            const newRows = [...additionalRows];
                                            newRows[index].drug = e.target.value;
                                            setAdditionalRows(newRows);
                                        }} /> : row.drug}</td>
                                        <td>{isEditing ? <input className="table-input" value={row.packaging} onChange={(e) => {
                                            const newRows = [...additionalRows];
                                            newRows[index].packaging = e.target.value;
                                            setAdditionalRows(newRows);
                                        }} /> : row.packaging}</td>
                                        <td>{isEditing ? <input className="table-input" type="number" value={row.unitPrice} onChange={(e) => {
                                            const newRows = [...additionalRows];
                                            newRows[index].unitPrice = parseFloat(e.target.value) || 0;
                                            setAdditionalRows(newRows);
                                        }} /> : row.unitPrice}</td>
                                        <td>{isEditing ? <input className="table-input" type="number" value={row.quantity} onChange={(e) => {
                                            const newRows = [...additionalRows];
                                            newRows[index].quantity = parseInt(e.target.value) || 0;
                                            setAdditionalRows(newRows);
                                        }} /> : row.quantity}</td>
                                        <td>{isEditing ? <input className="table-input" type="number" value={row.totalPrice} onChange={(e) => handleTotalChange(index, e)} /> : row.totalPrice}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}
                <button onClick={handleAddRow}>+</button>
                <div className="discount-section">
                    <label htmlFor="discount">Discount:</label>
                    <input
                        type="number"
                        id="discount"
                        name="discount"
                        value={discount}
                        onChange={handleDiscountChange}
                    />
                </div>
                <div className="total-bill">
                    <h3>Total Bill: {totalBill}</h3>
                </div>
            </div>
            <div className="action-buttons">
                <button className="confirm-bill-button" onClick={handleConfirmBill} disabled={confirmingBill}>
                    {confirmingBill ? "Confirming Bill..." : "Confirm Bill"}
                </button>
                <button className="print-bill-button" onClick={handlePrintBill}>Print Bill</button>
            </div>
        </div>
    );
}

export default MedicalBillModal;

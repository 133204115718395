import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, LineElement, CategoryScale, LinearScale, PointElement, Tooltip, Legend } from 'chart.js';
import { urls } from './config.dev';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faTimes } from '@fortawesome/free-solid-svg-icons';

// Register required Chart.js components
ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement, Tooltip, Legend);

function PTmodal({ token, onClose }) {
  const [performanceTrends, setPerformanceTrends] = useState([]);
  const [visibleEmployees, setVisibleEmployees] = useState({});
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const currentYear = new Date().getFullYear(); // Get the current year

  useEffect(() => {
    fetchPerformanceTrends();
  }, []);

  const fetchPerformanceTrends = async () => {
    try {
      setLoading(true);
      const response = await fetch(urls.performanceTrends, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token }),
      });

      const data = await response.json();
      if (data.error) {
        setErrorMessage(data.error);
        return;
      }

      const { firstEmployee, performanceTrends: trends } = data;
      const firstEmployeeExists = trends.some(employee => employee.name.trim() === firstEmployee.trim());

      // Use firstEmployee if it exists, otherwise select a random employee
      const initialEmployee = firstEmployeeExists ? firstEmployee : trends[Math.floor(Math.random() * trends.length)].name;

      // Set visibility based on the initial employee
      const visibility = trends.reduce((acc, employee) => {
        acc[employee.name] = employee.name === initialEmployee;
        return acc;
      }, {});

      setPerformanceTrends(trends);
      setVisibleEmployees(visibility);
    } catch (error) {
      console.error('Error fetching performance trends:', error);
      setErrorMessage('Failed to load performance trends');
    } finally {
      setLoading(false);
    }
  };

  const handleToggleEmployee = (name) => {
    setVisibleEmployees((prev) => ({
      ...prev,
      [name]: !prev[name],
    }));
  };

  const getLineGraphData = () => {
    const labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    const datasets = performanceTrends
      .filter(employee => visibleEmployees[employee.name])
      .map(employee => {
        // Create a full dataset with null values for the months not covered
        const data = labels.map((_, index) => {
          return index < employee.monthlyScores.length ? parseInt(employee.monthlyScores[index]) : null;
        });

        return {
          label: employee.name,
          data: data, // Use the modified data array
          borderColor: `hsl(${Math.random() * 360}, 100%, 50%)`,
          fill: false,
        };
      });

    return {
      labels,
      datasets,
    };
  };

  return (
    <div style={{
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100vw',
      height: '100vh',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 1000,
    }}>
      <div style={{
        backgroundColor: '#f6fff8',
        width: '100%',
        height: '100%',
        maxWidth: '100vw',
        maxHeight: '100vh',
        borderRadius: '10px',
        overflow: 'hidden',
        position: 'relative',
      }}>
        <div style={{
    backgroundColor: '#fafaff', // White background for the header
    padding: '10px 20px',
    display: 'flex', // Use flexbox for layout
    justifyContent: 'space-between', // Space between heading and button
    alignItems: 'center', // Align items vertically centered
}}>
    <h2 style={{ 
        margin: 0, 
        color: 'black', 
        fontWeight: 'bold', 
        textTransform: 'uppercase', 
        textAlign: 'center',        
        fontSize: '28px'           
      }}>
        Employee Performance Trends for {currentYear}  {/* Updated heading */}
      </h2>

    <button
        onClick={onClose}
        style={{
            background: 'none',
            border: 'none',
            fontSize: '20px',
            cursor: 'pointer',
            color: 'red', // Red close button
        }}
    >
        <FontAwesomeIcon icon={faTimes} />
    </button>
</div>


        {loading ? (
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '200px',
          }}>
            <FontAwesomeIcon icon={faSpinner} spin size="2x" />
          </div>
        ) : errorMessage ? (
          <p style={{ textAlign: 'center', color: 'red' }}>{errorMessage}</p>
        ) : (
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            padding: '20px',
            height: 'calc(100% - 60px)', // Ensures the content takes up the full height
          }}>
            <div style={{ display: 'flex', height: '100%' }}>
              <div style={{
                flex: 3,
                backgroundColor: 'black', // Black background for the graph
                padding: '20px', // Optional padding
                borderRadius: '0px', // Optional rounded corners
              }}>
               <Line 
  data={getLineGraphData()} 
  options={{
    scales: {
      x: {
        title: {
          display: true,
          text: 'Months',
          color: 'white', // White color for the x-axis title
        },
        grid: {
          color: 'rgba(255, 255, 255, 0.3)', // Faint color for the x-axis grid lines
        },
        ticks: {
          color: 'white', // Color of the x-axis tick labels
        },
      },
      y: {
        title: {
          display: true,
          text: 'Percentage Perfomance Analysis',
          color: 'white', // White color for the y-axis title
        },
        grid: {
          color: 'rgba(255, 255, 255, 0.3)', // Faint color for the y-axis grid lines
        },
        ticks: {
          color: 'white', // Color of the y-axis tick labels
        },
      },
    },
  }}
/>

              </div>
              <div style={{
                flex: 1,
                padding: '10px',
                backgroundColor: '#e4ffea',
                borderLeft: '1px solid #ddd',
                overflowY: 'auto', // Allow scrolling if the employee list is too long
                maxHeight: '100%', // Limit the height of the employee list
              }}>
               <h3 style={{ color: 'black' }}>Toggle Employee Lines</h3>

                <ul style={{ listStyleType: 'none', padding: 0 }}>
                  {performanceTrends.map((employee) => (
                    <li key={employee.name} style={{ marginBottom: '10px' }}>
                      <label style={{ cursor: 'pointer' }}>
                        <input
                          type="checkbox"
                          checked={visibleEmployees[employee.name]}
                          onChange={() => handleToggleEmployee(employee.name)}
                        />
                        {employee.name}
                      </label>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            {/* For remaining employees, list them below */}
            <div style={{ marginTop: '20px', overflowY: 'auto' }}>
              {performanceTrends.length > 0 && (
                <ul style={{ listStyleType: 'none', padding: 0 }}>
                  {performanceTrends
                    .filter(employee => !visibleEmployees[employee.name])
                    .map(employee => (
                      <li key={employee.name} style={{ marginBottom: '10px' }}>
                        <label style={{ cursor: 'pointer' }}>
                          <input
                            type="checkbox"
                            checked={visibleEmployees[employee.name]}
                            onChange={() => handleToggleEmployee(employee.name)}
                          />
                          {employee.name}
                        </label>
                      </li>
                    ))}
                </ul>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default PTmodal;

import React, { useState, useEffect } from 'react';
import { urls } from './config.dev'; // Import the backend URLs
import AntenatalPrompt from './AntenatalPrompt';
import PartogramPrompt from './PartogramPrompt';
import './Mothers.css'; // All styles should be moved here
import { useNavigate } from 'react-router-dom';

const Mothers = () => {
  const [selectedMother, setSelectedMother] = useState(null);
  const [openPrompt, setOpenPrompt] = useState(null); // 'antenatal' or 'partogram'
  const [mothersData, setMothersData] = useState([]); // Holds mothers' data
  const [clinicName, setClinicName] = useState(''); // State to store clinic name
  const [employeeName, setEmployeeName] = useState(''); // State to store employee name
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const tokenFromUrl = urlParams.get('token');

  useEffect(() => {
    const fetchTokenAndCheckSecurity = async () => {
      try {
        const params = new URLSearchParams(window.location.search);
        const tokenFromUrl = params.get('token');

        const securityResponse = await fetch(urls.security, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ token: tokenFromUrl }),
        });

        if (securityResponse.ok) {
          const securityData = await securityResponse.json();

          if (securityData.message === 'Session valid') {
            setEmployeeName(securityData.employee_name);
            setClinicName(securityData.clinic);
            localStorage.setItem('token', securityData.clinic_session_token);
          } else if (securityData.error === 'Session expired') {
            navigate(`/dashboard?token=${securityData.clinic_session_token}`);
          } else {
            navigate('/login');
          }
        } else {
          throw new Error('Failed to perform security check');
        }
      } catch (error) {
        console.error('Error performing security check:', error);
        navigate('/login');
      }
    };

    fetchTokenAndCheckSecurity();
  }, [navigate]);

  useEffect(() => {
    const fetchMothers = async () => {
      try {
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('token');

        if (!token) {
          console.error('Token is missing from the URL');
          return;
        }

        const payload = { token };

        const response = await fetch(urls.fetchMothers, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        });

        if (!response.ok) {
          throw new Error('Failed to fetch mothers data');
        }

        const data = await response.json();
        setMothersData(data);
      } catch (error) {
        console.error('Error fetching mothers data:', error);
      }
    };

    fetchMothers();
  }, []);

  const handleOpenPrompt = (promptType, mother) => {
    const urlParams = new URLSearchParams(window.location.search);
    const tokenFromUrl = urlParams.get('token');

    const dataToSend = {
      firstName: mother.first_name,
      lastName: mother.last_name,
      age: mother.age,
      address: mother.address,
      phoneNumber: mother.phone_number,
      maternityId: mother.maternity_id,
      status: mother.status,
      token: tokenFromUrl,
      clinicName,
      employeeName,
    };

    setSelectedMother(mother);
    setOpenPrompt(promptType);
  };

  const handleClosePrompt = () => {
    setSelectedMother(null);
    setOpenPrompt(null);
  };

  return (
    <div className="mothers-container">
      {mothersData.length === 0 ? (
        <p>Loading mothers data...</p>
      ) : (
        mothersData.map((mother) => (
          <div key={mother.maternity_id} className="mother-card">
            <h3>{`${mother.first_name} ${mother.last_name}`.toUpperCase()}</h3>
            <p>Age: {mother.age}</p>
            <p>Phone: {mother.phone_number}</p>
            <p>EDD: {mother.EDD}</p>
            <p>Status: {mother.status}</p>
  
            <div className="card-actions">
              <span
                role="img"
                aria-label="Antenatal Card"
                className="action-emoji"
                onClick={() => handleOpenPrompt('antenatal', mother)}
              >
                🍼
              </span>
              <span
                role="img"
                aria-label="Partogram"
                className="action-emoji"
                onClick={() => handleOpenPrompt('partogram', mother)}
              >
                📊
              </span>
            </div>
          </div>
        ))
      )}
  
      {/* Conditionally render the prompts based on state */}
      {openPrompt && selectedMother && (
        <div
          style={{
            position: 'fixed',
            top: '0',
            left: '0',
            width: '100vw',
            height: '100vh',
            backgroundColor: '#000', // Fully black background
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1000,
          }}
        >
          {openPrompt === 'antenatal' && (
            <AntenatalPrompt
              {...{ ...selectedMother, clinicName, employeeName, tokenFromUrl }}
              onClose={handleClosePrompt}
              style={{
                position: 'relative',
                backgroundColor: '#fff',
                padding: '30px',
                borderRadius: '8px',
                width: '95vw', // Increased width to cover more screen
                maxWidth: '1500px', // Adjusted maximum width
                height: '85vh', // Slightly taller modal
                display: 'flex',
                flexDirection: 'column',
                overflowY: 'auto',
              }}
            />
          )}
  
          {openPrompt === 'partogram' && (
            <PartogramPrompt
              {...{ ...selectedMother, clinicName, employeeName }}
              onClose={handleClosePrompt}
              style={{
                position: 'relative',
                background: '#ffffff',
                padding: '30px',
                borderRadius: '8px',
                width: '90%',
                maxWidth: '600px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                overflowY: 'auto',
              }}
            />
          )}
        </div>
      )}
    </div>
  );
  
};

export default Mothers;

import React, { useState, useEffect } from 'react';
import { urls } from './config.dev';
import printJS from 'print-js'; // Make sure to install print-js: yarn add print-js

function InvestigationReceiptModal({ isOpen, onClose, details, token }) {
  const [clinicDetails, setClinicDetails] = useState(null);
  const { patient_name, file_id, contact_id, tests, totalBill } = details;

   // Add console.log for debugging
   useEffect(() => {
    if (isOpen) {
      console.log('Modal open');
      console.log('Received details from parent:', details);
    }
  }, [isOpen, details]);

  // Function to fetch clinic details
  useEffect(() => {
    if (token && isOpen) {
      const fetchClinicDetails = async () => {
        try {
          const response = await fetch(urls.fetchclinicdetails, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ token }), // Send token in the payload
          });

          if (!response.ok) {
            throw new Error('Failed to fetch clinic details');
          }

          const data = await response.json();
          setClinicDetails(data);
        } catch (error) {
          console.error('Error fetching clinic details:', error);
        }
      };

      fetchClinicDetails();
    }
  }, [token, isOpen]);

  const handlePrintReceipt = () => {
    const currentDate = new Date().toLocaleString();
    const receiptHtml = `
      <html>
      <head>
        <style>
          body {
            font-family: Arial, sans-serif;
            margin: 0;
            padding: 0;
            width: 80mm; /* Adjust to match printer width */
            height: auto;
          }
          .receipt-content {
            padding: 0;
            margin: 0;
            width: 100%;
            text-align: center;
          }
          .clinic-heading {
            font-size: 28px; /* Increased font size for clinic name */
            font-weight: bold;
            margin: 0;
          }
          .clinic-details {
            font-size: 18px; /* Increased font size for clinic details */
            margin: 5px 0;
          }
          .receipt-details {
            font-size: 16px; /* Increased font size for receipt details */
            text-align: left;
            margin: 10px 0;
          }
          .receipt-details p {
            margin: 5px 0;
          }
          .receipt-footer {
            font-size: 14px; /* Increased font size for footer */
            text-align: center;
            color: #555;
            margin-top: 20px;
          }
          .star-line {
            text-align: center;
            margin: 20px 0;
            font-size: 14px; /* Increased font size for star line */
          }
        </style>
      </head>
      <body>
        <div class="receipt-content">
          <h2 class="clinic-heading">${clinicDetails.clinic_name}</h2>
          <div class="clinic-details">
            <p>${clinicDetails.district}, ${clinicDetails.sub_county}</p>
            <p>Contact: ${clinicDetails.owners_contact}</p>
            <p>${currentDate}</p>
          </div>
          <div class="receipt-details">
            <p><strong>Client Name:</strong> ${patient_name}</p>
            <p><strong>File ID:</strong> ${file_id}</p>
            <p><strong>Contact ID:</strong> ${contact_id}</p>
            <p><strong>Tests/Exams:</strong></p>
            ${tests.map(test => `<p>${test.name}: UGX ${parseFloat(test.price).toFixed(2)}</p>`).join('')}
            <p><strong>Total Bill:</strong> UGX ${totalBill.toFixed(2)}</p>
          </div>
         -----------------------------------------------------------------------
            <p>Software developed by: DeepMind E-systems 0786747733</p>
            <p>Thank you for choosing our service!</p> <!-- Added Thank You statement -->
          </div>
        
        </div>
      </body>
      </html>
    `;
  
    printJS({
      printable: receiptHtml,
      type: 'raw-html',
      documentTitle: 'Receipt',
    });
  };
  

  if (!isOpen || !clinicDetails) return null; // Don't render if modal is not open or clinic details are not loaded

  const currentDateTime = new Date().toLocaleString();

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h3>PAYMENT SUCCESSFUL</h3>
        <h4>Receipt Details</h4>
        <p>Name: {patient_name}</p>
        <p>File ID: {file_id}</p>
        <p>Contact ID: {contact_id}</p>

        <h4>Tests/Exams</h4>
        {tests.map((test, index) => (
          <p key={index}>
            {test.name}: UGX {parseFloat(test.price).toFixed(2)}
          </p>
        ))}
        <p>Total Bill: UGX {totalBill.toFixed(2)}</p>

        {/*<h4>Clinic Details</h4>
        <p>Clinic Name: {clinicDetails.clinic_name}</p>
        <p>District: {clinicDetails.district}</p>
        <p>Sub-county: {clinicDetails.sub_county}</p>
        <p>Contact: {clinicDetails.owners_contact}</p>
        <p>Email: {clinicDetails.owners_email}</p>

        <h4>Date & Time</h4>
        <p>{currentDateTime}</p>*/}

        <button onClick={handlePrintReceipt}>Print Receipt</button>
        <button onClick={onClose}>Close Receipt</button>
      </div>
    </div>
  );
}

export default InvestigationReceiptModal;

import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { urls } from './config.dev';
import './RadiographerDashboard.css';
import Topbar from './Topbar';
import RadiologyResultsModal5 from './RadiologyResultsModal5';

const RadiographerDashboard = () => {
  const [pendingExamsCount, setPendingExamsCount] = useState(0);
  const [recentExams, setRecentExams] = useState([]);
  const [employeeName, setEmployeeName] = useState('');
  const [clinicDetails, setClinicDetails] = useState(null);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [isRadiologyResultsModalOpen, setIsRadiologyResultsModalOpen] = useState(false);
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const urlToken = useRef(params.get('token'));

  useEffect(() => {
    const fetchTokenAndCheckSecurity = async () => {
      try {
        const tokenFromUrl = urlToken.current;

        const securityResponse = await fetch(urls.security, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ token: tokenFromUrl }),
        });

        if (!securityResponse.ok) throw new Error('Failed to perform security check');

        const securityData = await securityResponse.json();

        if (securityData.message === 'Session valid') {
          setEmployeeName(securityData.employee_name);
          await Promise.all([fetchRecentExams(tokenFromUrl), fetchClinicDetails(tokenFromUrl)]);
        } else if (securityData.error === 'Session expired') {
          navigate(`/dashboard?token=${securityData.clinic_session_token}`);
        } else {
          navigate('/login');
        }
      } catch (error) {
        console.error('Error performing security check:', error);
        navigate('/login');
      }
    };

    fetchTokenAndCheckSecurity();
  }, [navigate]);

  useEffect(() => {
    const fetchPendingExamsCount = async (token) => {
      try {
        const response = await fetch(urls.pendingexamscount, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ token }),
        });

        if (!response.ok) throw new Error('Failed to fetch pending exams count');

        const data = await response.json();
        setPendingExamsCount(data.count);
      } catch (error) {
        console.error('Error fetching pending exams count:', error);
      }
    };

    const tokenFromUrl = urlToken.current;
    if (tokenFromUrl) {
      fetchPendingExamsCount(tokenFromUrl);
      const interval = setInterval(() => fetchPendingExamsCount(tokenFromUrl), 7000);
      return () => clearInterval(interval);
    }
  }, []);

  const fetchRecentExams = async (token) => {
    try {
      const response = await fetch(urls.allradiology, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token }),
      });

      if (response.ok) {
        const data = await response.json();
        setRecentExams(data.patients || []);
      } else {
        throw new Error('Failed to fetch recent radiology exams');
      }
    } catch (error) {
      console.error('Error fetching recent radiology exams:', error);
    }
  };

  const fetchClinicDetails = async (token) => {
    try {
      const response = await fetch(urls.fetchclinicdetails, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token }),
      });
      if (response.ok) {
        const data = await response.json();
        setClinicDetails(data);
      } else {
        throw new Error('Failed to fetch clinic details');
      }
    } catch (error) {
      console.error('Error fetching clinic details:', error);
    }
  };

  const handleShowRadiologyResults = (patient) => {
    setSelectedPatient({
      ...patient,
      clinicDetails,
    });
    setIsRadiologyResultsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsRadiologyResultsModalOpen(false);
  };

  const handleGoToPendingExams = () => {
    const tokenFromUrl = urlToken.current;
    navigate(`/radiology?token=${tokenFromUrl}`);
  };

  return (
    <div className="radiographer-dashboard-container" style={{ marginTop: '80px' }}>
      <Topbar token={urlToken.current} />

      {/* Pending Exams Statement */}
      <div className="header-container" style={{ animation: pendingExamsCount > 0 ? 'pulse 1.5s infinite' : 'none' }}>
        {pendingExamsCount > 0 ? (
          <div
            className={`pending-exams ${pendingExamsCount > 0 ? 'active' : ''}`}
            onClick={handleGoToPendingExams}
            style={{
              padding: '15px',
              backgroundColor: '#ffffff', // Set background to white
              color: '#ff0000', // Set text color to red
              border: '1px solid #f5c6cb', // Change this to a red border if desired
              borderRadius: '5px',
              cursor: 'pointer',
              marginBottom: '20px',
              marginLeft: '20px', // Adjust the value as needed for spacing
              textAlign: 'center', // Center the text
            }}
            
          >
            Hello {employeeName}, you have {pendingExamsCount} patient{pendingExamsCount > 1 ? 's' : ''} waiting for radiology services. Click here to see them.
          </div>
        ) : (
          <div style={{ padding: '15px', marginBottom: '20px' }}>
            Hello {employeeName}, there are no patients waiting for radiology services at the moment.
          </div>
        )}
      </div>

      {/* Recent Radiology Exams Section */}
      <h2 style={{ textAlign: 'left', fontSize: '30px', marginBottom: '20px' }}>
        Recent Exams Done
      </h2>
      <div className="recent-exams-container" style={{ display: 'flex', flexWrap: 'wrap', gap: '20px' }}>
  {recentExams.length === 0 ? (
    <p>No recent radiology exams available.</p>
  ) : (
    recentExams.map((patient) => (
      <div
        key={patient.file_id}
        className="exam-card"
        style={{
          padding: '20px',
          border: '1px solid #ccc',
          borderRadius: '5px',
          boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
          width: 'calc(25% - 20px)', // Four cards per row
          boxSizing: 'border-box',
          display: 'flex',
          flexDirection: 'column', // Ensure items are stacked vertically
          justifyContent: 'space-between', // Push button to the bottom
          textAlign: 'left',
        }}
      >
        {/* Patient Name */}
        <h3
          style={{
            color: 'black',
            fontWeight: 'bold',
            textAlign: 'center',
            textTransform: 'uppercase', // Make the name uppercase
          }}
        >
          {patient.first_name} {patient.last_name}
        </h3>

        {/* Patient Details */}
        <div style={{ color: 'black', marginTop: '10px' }}>
          <p>Age: {patient.age}</p>
          <p>Sex: {patient.sex}</p>
          <p>{patient.radiology_exam}</p>
        </div>

        {/* Edit Results Button */}
        <button
          onClick={() => handleShowRadiologyResults(patient)}
          style={{
            backgroundColor: '#007bff',
            color: 'white',
            border: 'none',
            padding: '10px',
            cursor: 'pointer',
            textAlign: 'center',
            width: '100%',
            marginTop: 'auto', // Push the button to the bottom
            borderRadius: '0px',
          }}
        >
          Edit Results
        </button>
      </div>
    ))
  )}
</div>


      {/* Radiology Results Modal */}
      {isRadiologyResultsModalOpen && (
        <RadiologyResultsModal5
          patient={selectedPatient}
          clinicDetails={clinicDetails}
          onClose={handleCloseModal}
        />
      )}
    </div>
  );
};

export default RadiographerDashboard;

import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { urls } from './config.dev';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFlask, faXRay } from '@fortawesome/free-solid-svg-icons';
import Resultmodal from './Resultmodal'; // Import Resultmodal component
import RadiologyResultsModal2 from './RadiologyResultsModal2'; // Import RadiologyResultsModal2 component
import './PatientsFiles.css';
import Topbar from './Topbar'; // Import the Topbar component
import Walkin from './Walkin'; // Ensure you import walkin.js
function PatientsFiles() {
    const [patients, setPatients] = useState([]);
    const [selectedPatient, setSelectedPatient] = useState(null);
    const [isLabResultsModalOpen, setIsLabResultsModalOpen] = useState(false);
    const [isRadiologyResultsModalOpen, setIsRadiologyResultsModalOpen] = useState(false);
    const [employeeName, setEmployeeName] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedDate, setSelectedDate] = useState('');
    const [loading, setLoading] = useState(true);
    const params = new URLSearchParams(window.location.search);
    const urlToken = params.get('token'); // Define urlToken here
    const [clinicDetails, setClinicDetails] = useState(null);
    const navigate = useNavigate();
    let abortController = new AbortController();
    const [isWalkinModalOpen, setIsWalkinModalOpen] = useState(false);
  



    useEffect(() => {
        const fetchTokenAndCheckSecurity = async () => {
            try {
                const params = new URLSearchParams(window.location.search);
                const tokenFromUrl = params.get('token');

                console.log('URL Token:', tokenFromUrl);

                const securityResponse = await fetch(urls.security, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ token: tokenFromUrl }),
                });

                if (securityResponse.ok) {
                    const securityData = await securityResponse.json();

                    if (securityData.message === 'Session valid') {
                        setEmployeeName(securityData.employee_name);
                        localStorage.setItem('token', securityData.clinic_session_token);
                        fetchPatients(tokenFromUrl);

                        // Fetch clinic details
                        await fetchClinicDetails(tokenFromUrl);
                    } else if (securityData.error === 'Session expired') {
                        navigate(`/dashboard?token=${securityData.clinic_session_token}`);
                    } else {
                        navigate('/login');
                    }
                } else {
                    throw new Error('Failed to perform security check');
                }
            } catch (error) {
                console.error('Error performing security check:', error);
                navigate('/login');
            }
        };

        fetchTokenAndCheckSecurity();
    }, [navigate]);

    useEffect(() => {
        setLoading(true);
        fetchPatients();

        return () => {
            abortController.abort();
        };
    }, [searchQuery, selectedDate]);

    const fetchPatients = useCallback(() => {
        abortController.abort();
        abortController = new AbortController();

        const params = new URLSearchParams(window.location.search);
        const tokenFromUrl = params.get('token');

        let apiUrl = `${urls.suggestfiles}?query=${searchQuery}`;
        if (selectedDate) {
            apiUrl += `&date=${selectedDate}`;
        }

        fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ token: tokenFromUrl }),
            signal: abortController.signal
        })
            .then(response => response.json())
            .then(data => {
                setPatients(data);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching patient data:', error);
                setLoading(false);
            });
    }, [searchQuery, selectedDate]);

    const fetchClinicDetails = async (token) => {
        try {
            const response = await fetch(urls.fetchclinicdetails, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ token }),
            });
            if (response.ok) {
                const data = await response.json();
                setClinicDetails(data);
            } else {
                throw new Error('Failed to fetch clinic details');
            }
        } catch (error) {
            console.error('Error fetching clinic details:', error);
        }
    };

    const handleViewEditPatientFile = (fileId) => {
        const params = new URLSearchParams(window.location.search);
        const tokenFromUrl = params.get('token');
        navigate(`/patient-file/${tokenFromUrl}/${fileId}`);
    };

    const handleShowLabResults = (patient) => {
        setSelectedPatient(patient);
        setIsLabResultsModalOpen(true);
    };

    const handleShowRadiologyResults = (patient) => {
        setSelectedPatient(patient);
        setIsRadiologyResultsModalOpen(true);
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const handleDateChange = (event) => {
        setSelectedDate(event.target.value);
    };
    const handleSeePatientsForScanOrLab = () => {
        setIsWalkinModalOpen(true); // Remove the rotation logic and open the modal directly
    };
    
    

    return (
        <div className="patients-page-container"> 

        <Topbar token={urlToken} />
        <h1>Patient Files</h1>
        
        {/* Button to open the modal */}
        <button className="rotate-button" onClick={handleSeePatientsForScanOrLab}>
            See Patients that Only Came for Scan or Lab
        </button>
            <div className="search-container">
                <input
                    type="text"
                    placeholder="Search by name..."
                    value={searchQuery}
                    onChange={handleSearchChange}
                />
                <div className="date-picker-container">
                    <label htmlFor="date-picker">Search by Date:</label>
                    <input
                        id="date-picker"
                        type="date"
                        value={selectedDate}
                        onChange={handleDateChange}
                    />
                </div>
            </div>
            {loading && <p>Loading patient files...</p>}
            {!loading && (
                <>
                   
                    <div className="patient-list">
                        {patients.length > 0 ? (
                            patients.map(patient => (
                                <div key={patient.contact_id} className="patient-card">
                                    <h3>{`${patient.first_name} ${patient.last_name}`}</h3>
                                    <p>{`File ID: ${patient.file_id}`}</p>
                                    <p>{`Age: ${patient.age}, Sex: ${patient.sex}`}</p>
                                    <p>{`Date & Time: ${patient.date_time}`}</p>
                                    {patient.lab_status_message && (
    <p>
       
        {patient.lab_status_message === 'Click here to view Lab results' ? (
            <span 
                className="clickable-status" 
                onClick={() => handleShowLabResults(patient)}
            >
                {patient.lab_status_message}
            </span>
        ) : (
            patient.lab_status_message
        )}
    </p>
)}

{patient.radiology_status_message && (
    <p>
       
        {patient.radiology_status_message === 'Click here to view Radiology results' ? (
            <span 
                className="clickable-status" 
                onClick={() => handleShowRadiologyResults(patient)}
            >
                {patient.radiology_status_message}
            </span>
        ) : (
            patient.radiology_status_message
        )}
    </p>
)}

                                    
{patient.amount && patient.amount !== '0.00' && (
    <div className="scrolling-text-container">
        <p className="scrolling-text">
            {`Amount spent on treating this patient: UGX ${parseFloat(patient.amount).toLocaleString()}`}
        </p>
    </div>
)}
                                   
                                    <button onClick={() => handleViewEditPatientFile(patient.file_id)}>View/Edit</button>
                                </div>
                            ))
                        ) : (
                            <p>No patient files found.</p>
                        )}
                    </div>
                </>
            )}
            {isLabResultsModalOpen && selectedPatient && (
                <Resultmodal 
                    patient={selectedPatient} 
                    onClose={() => setIsLabResultsModalOpen(false)} 
                    clinicDetails={clinicDetails} // Pass clinic details to Resultmodal
                />
            )}
            {isRadiologyResultsModalOpen && selectedPatient && (
                <RadiologyResultsModal2 
                    patient={selectedPatient} 
                    onClose={() => setIsRadiologyResultsModalOpen(false)} 
                    clinicDetails={clinicDetails} // Pass clinic details to RadiologyResultsModal2
                />
            )}
            {isWalkinModalOpen && (
    <div className="modal">
        <Walkin token={urlToken} onClose={() => setIsWalkinModalOpen(false)} />
    </div>
)}
        </div>
        
    );
}

export default PatientsFiles;

import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faExclamationCircle, faFileAlt } from '@fortawesome/free-solid-svg-icons';
import { urls } from './config.dev';
import { useNavigate } from 'react-router-dom';
import doctorsSvg from './images/doctors.svg'; // Import the SVG image
import './DoctorsDashboard.css';
import Topbar from './Topbar'; // Import the Topbar component
import Resultmodal2 from './Resultmodal2'; // Import Resultmodal component
import RadiologyResultsModal3 from './RadiologyResultsModal3'; // Import RadiologyResultsModal2 component

function DoctorsDashboard() {
  const [currentTime, setCurrentTime] = useState(new Date());
  const [triagePatients, setTriagePatients] = useState([]);
  const [waitingPaymentPatients, setWaitingPaymentPatients] = useState([]);
  const [inProcessPatients, setInProcessPatients] = useState([]);
  const [completedResultsPatients, setCompletedResultsPatients] = useState([]);
  const [employeeName, setEmployeeName] = useState('');
  const navigate = useNavigate();
  const [isRadiologyResultsModalOpen, setIsRadiologyResultsModalOpen] = useState(false);
const [isLabResultsModalOpen, setIsLabResultsModalOpen] = useState(false);
const [selectedPatient, setSelectedPatient] = useState(null);
const [clinicDetails, setClinicDetails] = useState(null); // Initialize as null

  

  const params = new URLSearchParams(window.location.search);
  const urlToken = params.get('token'); // Define urlToken here

  useEffect(() => {
    const fetchTokenAndCheckSecurity = async () => {
      try {
        console.log('URL Token:', urlToken);

        const securityResponse = await fetch(urls.security, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ token: urlToken }),
        });

        if (securityResponse.ok) {
          const securityData = await securityResponse.json();

          if (securityData.message === 'Session valid') {
            setEmployeeName(securityData.employee_name);
            localStorage.setItem('token', securityData.clinic_session_token);

            fetchTriagePatients(urlToken);
            fetchWaitingPaymentPatients(urlToken);
            fetchInProcessPatients(urlToken);
            fetchCompletedResultsPatients(urlToken);
          } else if (securityData.error === 'Session expired') {
            navigate(`/dashboard?token=${securityData.clinic_session_token}`);
          } else {
            navigate('/login');
          }
        } else {
          throw new Error('Failed to perform security check');
        }
      } catch (error) {
        console.error('Error performing security check:', error);
        navigate('/login');
      }
    };

    fetchTokenAndCheckSecurity();
  }, [navigate, urlToken]);

 

// Function to repeatedly fetch data every 10 seconds
const startFetchingData = () => {
  fetchTriagePatients(urlToken);
  fetchWaitingPaymentPatients(urlToken);
  fetchInProcessPatients(urlToken);
  fetchCompletedResultsPatients(urlToken);

  setInterval(() => {
    fetchTriagePatients(urlToken);
    fetchWaitingPaymentPatients(urlToken);
    fetchInProcessPatients(urlToken);
    fetchCompletedResultsPatients(urlToken);
  }, 10000); // 10 seconds = 10000 milliseconds
};

useEffect(() => {
  // Start fetching data when the component mounts
  startFetchingData();
  
  // Cleanup function to clear the interval if the component unmounts
  return () => clearInterval(startFetchingData);
}, []);

// Your fetch functions below

const fetchTriagePatients = (tokenFromUrl) => {
  fetch(urls.patientnames, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ token: tokenFromUrl }),
  })
    .then(response => response.json())
    .then(data => setTriagePatients(data.sort((a, b) => new Date(a.date_time) - new Date(b.date_time))))
    .catch(error => console.error('Error fetching triage patients:', error));
};

const fetchWaitingPaymentPatients = (tokenFromUrl) => {
  fetch(urls.awaitingnames, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ token: tokenFromUrl }),
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(data => {
      console.log('Full API response:', data);
      const patients = data || [];
      setWaitingPaymentPatients(patients);
      console.log('Fetched patients:', patients);
    })
    .catch(error => console.error('Error fetching waiting payment patients:', error));
};

const fetchInProcessPatients = (tokenFromUrl) => {
  fetch(urls.inprocessnames, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ token: tokenFromUrl }),
  })
    .then(response => response.json())
    .then(data => setInProcessPatients(data || []))
    .catch(error => console.error('Error fetching in-process patients:', error));
};

const fetchCompletedResultsPatients = (tokenFromUrl) => {
  fetch(urls.completedlabnames, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ token: tokenFromUrl }),
  })
    .then(response => response.json())
    .then(data => {
      console.log('Full API response for completed patients:', data);
      const completedPatients = data.map(patient => ({
        ...patient,
        status_type: patient.status_type || 'Completed',
      }));
      setCompletedResultsPatients(completedPatients);
      console.log('Fetched completed patients:', completedPatients);
    })
    .catch(error => console.error('Error fetching completed results patients:', error));
};

  const handlePatientClick = (patient) => {
    console.log('Clicked on patient:', patient);
  };
  const handlePatientClick2 = (patient) => {
    setSelectedPatient(patient);
    setClinicDetails(patient); // Set clinicDetails to the clicked patient data
  
    if (patient.status_type === 'radiology') {
      setIsRadiologyResultsModalOpen(true);
    } else if (patient.status_type === 'lab') {
      setIsLabResultsModalOpen(true);
    }
  }
  const attentionColor = (attention) => {
    if (attention === 'critical') return 'red blinking';
    if (attention === 'moderate') return 'orange';
    return 'green';
  };

  const renderPatientCard = (patient) => (
    <div key={patient.contact_id} className={`patient-card ${attentionColor(patient.attention)}`} onClick={() => handlePatientClick(patient)}>
      <p><strong>Name:</strong> {patient.first_name} {patient.last_name}</p>
      <p><strong>Age:</strong> {patient.age}</p>
      <p><strong>Blood Pressure:</strong> {patient.blood_pressure}</p>
      <p><strong>Temperature:</strong> {patient.temperature}</p>
      <p><strong>SPO2:</strong> {patient.spo2}</p>
      <p><strong>Weight:</strong> {patient.body_weight}</p>
      <p><strong>Height:</strong> {patient.height}</p>
      <p><strong>Respiratory Rate:</strong> {patient.respiratory_rate}</p>
      <p><strong>Pulse Rate:</strong> {patient.pulse_rate}</p>
      <p><strong>State:</strong> {patient.state}</p>
      <p><strong>Visit Type:</strong> {patient.visit}</p>
      <p><strong>Sent by:</strong> {patient.employee_name}</p>
      <p><strong>Attention:</strong> {patient.attention}</p>
      <p><strong>Message:</strong> {patient.message || 'No message'}</p>
      <p><strong>Date and Time:</strong> {patient.date_time}</p>
    </div>
  );

  const getAttentionCount = (attentionType) => triagePatients.filter(patient => patient.attention === attentionType).length;

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);
    return () => clearInterval(interval);
  }, []);
// Function to classify temperature
const classifyTemperature = (temperature) => {
  if (temperature < 35) {
      return 'Warning: Very Low Temperature';
  } else if (temperature >= 35 && temperature < 36.5) {
      return 'Warning: Low Temperature';
  } else if (temperature >= 36.5 && temperature <= 37.5) {
      return 'Temperature Normal';
  } else if (temperature > 37.5 && temperature <= 38.5) {
      return 'Warning: High Temperature';
  } else {
      return 'Warning: Very High Temperature';
  }
};

// Function to classify respiratory rate
const classifyRespiratoryRate = (rate) => {
  if (rate < 12) {
      return 'Warning: Low Respiratory Rate';
  } else if (rate > 20) {
      return 'Warning: High Respiratory Rate';
  } else {
      return 'Respiratory Rate Normal';
  }
};

// Function to classify SPO2
const classifySPO2 = (spo2) => {
  if (spo2 < 90) {
      return 'Warning: Low SPO2';
  } else {
      return 'SPO2 Normal';
  }
};

// Function to calculate BMI
const calculateBMI = (weight, height) => {
  if (weight && height) {
      const heightInMeters = height / 100; // Convert cm to meters
      return (weight / (heightInMeters * heightInMeters)).toFixed(2);
  }
  return '';
};

// Function to classify BMI
const getBMIWarning = (bmi) => {
  if (bmi < 18.5) {
      return 'Warning: Underweight';
  } else if (bmi >= 18.5 && bmi <= 24.9) {
      return 'Normal: Healthy weight';
  } else if (bmi >= 25 && bmi <= 29.9) {
      return 'Warning: Overweight';
  } else if (bmi >= 30) {
      return 'Warning: Obese';
  }
  return '';
};
  // Function to classify pulse rate
  const classifyPulseRate = (pulseRate) => {
    if (pulseRate < 60) {
        return { status: 'Warning: Low Pulse Rate', level: 'low' };
    } else if (pulseRate > 100) {
        return { status: 'Warning: High Pulse Rate', level: 'high' };
    } else {
        return { status: 'Pulse Rate Normal', level: 'normal' };
    }
};

// Function to classify blood pressure
const classifyBloodPressure = (bloodPressure) => {
    const [systolic, diastolic] = bloodPressure.split('/').map(Number);

    if (systolic < 90 || diastolic < 60) {
        return { status: 'Warning: Low Blood Pressure', level: 'low' };
    } else if (systolic > 140 || diastolic > 90) {
        return { status: 'Warning: High Blood Pressure', level: 'high' };
    } else {
        return { status: 'Blood Pressure Normal', level: 'normal' };
    }
};


  return (
    <div className="full-screen-container">
      <Topbar token={urlToken} />
      <h1 className="doctors-room-heading">DOCTOR'S ROOM</h1>
      <div className="doctors-dashboard">
   
        <div className="patients-container triage-patients">
          <div className="container-header">
            <h2>Patients Coming in from Triage</h2>
            <span className="patient-count">
              Critical: <span className="critical-count blinking">{getAttentionCount('critical')}</span> | Moderate: {getAttentionCount('average')} | Mild: {getAttentionCount('routine')}
            </span>
          </div>
          <div className="patients-list" style={{ display: 'flex', flexWrap: 'wrap', gap: '15px' }}>
          {triagePatients.map(patient => {
  const temperatureClassification = classifyTemperature(patient.temperature);
  const respiratoryRateClassification = classifyRespiratoryRate(patient.respiratory_rate);
  const spo2Classification = classifySPO2(patient.spo2);
  const bmi = calculateBMI(patient.body_weight, patient.height);
  const bmiClassification = bmi ? getBMIWarning(bmi) : '';
  
  const bloodPressureClassification = classifyBloodPressure(patient.blood_pressure || 'N/A');
  const pulseRateClassification = classifyPulseRate(patient.pulse_rate || 'N/A');
  
  // Determine if the card should blink based on attention level
  const cardClassName = patient.attention === 'critical' ? 'blinking-card' : '';

  // Click handler for navigating to patient file
  const handleCardClick = () => {
    navigate(`/patient-file/${urlToken}/${patient.file_id}`);
  };

  return (
    <div
      key={patient.contact_id}
      className={cardClassName} // Apply blinking class conditionally
      style={{
        flex: '1 0 30%', // Adjust the width of each card
        border: '1px solid #ccc',
        padding: '10px',
        borderRadius: '5px',
        boxSizing: 'border-box',
        cursor: 'pointer', // Change cursor to pointer
      }}
      onClick={handleCardClick} // Add click handler
    >
      <div style={{ fontSize: '14px', fontWeight: 'bold' }}>
        Name: <span style={{ fontWeight: 'normal' }}>{`${patient.first_name} ${patient.last_name}`}</span>
      </div>
      <div style={{ fontSize: '12px' }}>Age: <span>{patient.age || 'N/A'}</span></div>
      
      <div style={{ fontSize: '12px' }}>
        Blood Pressure: <span>{patient.blood_pressure || 'N/A'} ({bloodPressureClassification.status})</span>
      </div>
      
      <div style={{ fontSize: '12px' }}>
        Temperature: <span>{patient.temperature || 'N/A'} ({temperatureClassification})</span>
      </div>
      
      <div style={{ fontSize: '12px' }}>
        SPO2: <span>{patient.spo2 || 'N/A'} ({spo2Classification})</span>
      </div>
      
      <div style={{ fontSize: '12px' }}>
        Respiratory Rate: <span>{patient.respiratory_rate || 'N/A'} ({respiratoryRateClassification})</span>
      </div>
      
      <div style={{ fontSize: '12px' }}>
        Weight: <span>{patient.body_weight || 'N/A'}</span>
      </div>
      
      <div style={{ fontSize: '12px' }}>
        Height: <span>{patient.height || 'N/A'}</span>
      </div>
      
      <div style={{ fontSize: '12px' }}>
        BMI: <span>{bmi || 'N/A'} {bmiClassification && `(${bmiClassification})`}</span>
      </div>

      <div style={{ fontSize: '12px' }}>
        Pulse Rate: <span>{patient.pulse_rate || 'N/A'} ({pulseRateClassification.status})</span>
      </div>
      <div style={{ fontSize: '12px' }}>State: <span>{patient.state || 'N/A'}</span></div>
      <div style={{ fontSize: '12px' }}>Visit Type: <span>{patient.visit || 'N/A'}</span></div>
      <div style={{ fontSize: '12px' }}>Sent by: <span>{patient.employee_name || 'N/A'}</span></div>
      <div style={{ fontSize: '12px' }}>Attention: <span>{patient.attention || 'N/A'}</span></div>
      <div style={{ fontSize: '12px' }}>Message: <span>{patient.message || 'N/A'}</span></div>
      <div style={{ fontSize: '12px' }}>Date and Time: <span>{patient.date_time}</span></div>
    </div>
  );
})}

</div>

        </div>

        {/* Other sections remain as they are */}
        <div className="patients-container waiting-payment-patients" style={{ fontFamily: 'Arial, sans-serif', fontSize: '12px' }}>
      <div className="container-header">
        <h2>Patients at the Cashier Paying for Investigations</h2>
        <span className="patient-count">{waitingPaymentPatients.length}</span>
      </div>
      <div className="patients-list" style={{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between'
      }}>
        {waitingPaymentPatients.map((patient, index) => (
  <div key={index} className="patient-card" style={{
    flex: '0 0 calc(33.33% - 10px)',
    margin: '5px',
    backgroundColor: '#f9f9f9',
    padding: '10px',
    border: '1px solid #ccc',
    borderRadius: '5px',
    lineHeight: '1.2',
    maxHeight: '150px',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column'
  }}>
    <p style={{ margin: '0' }}><strong>Name:</strong> {patient.name}</p>
    <p style={{ margin: '0' }}><strong>Age:</strong> {patient.age}</p>
    <p style={{ margin: '0' }}><strong>Contact:</strong> {patient.contact}</p>
    <p style={{ margin: '0' }}><strong>Sex:</strong> {patient.sex}</p>
    <p style={{ margin: '0' }}><strong>Status:</strong> {patient.status_type || "Making Payment for Investigations"}</p>
  </div>
))}
      </div>
    </div>

        <div className="patients-container in-process-patients" style={{ fontFamily: 'Arial, sans-serif', fontSize: '12px' }}>
  <div className="container-header">
    <h2>Patients in Laboratory and Radiology Room</h2>
    <span className="patient-count">{inProcessPatients.length}</span>
  </div>
  <div className="patients-list" style={{
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between'
  }}>
    {inProcessPatients.map((patient, index) => (
      <div key={index} className="patient-card" style={{
        flex: '0 0 calc(33.33% - 10px)',
        margin: '5px',
        backgroundColor: '#f9f9f9',
        padding: '10px',
        border: '1px solid #ccc',
        borderRadius: '5px',
        lineHeight: '1.2',
        maxHeight: '150px', // Set a max height for the card
        overflow: 'hidden',  // Hide overflow content
        display: 'flex',
        flexDirection: 'column' // Allow stacking
      }}>
        <p style={{ margin: '0' }}><strong>Name:</strong> {patient.name}</p>
        <p style={{ margin: '0' }}><strong>Age:</strong> {patient.age}</p>
        <p style={{ margin: '0' }}><strong>Contact:</strong> {patient.contact}</p>
        <p style={{ margin: '0' }}><strong>Sex:</strong> {patient.sex}</p>
        <p style={{ margin: '0' }}><strong>Location:</strong> {patient.status_type}</p>
        <div style={{ 
          margin: '0', 
          overflowY: 'auto', // Allow vertical scrolling
          maxHeight: '40px', // Set a max height for the tests/exams section
          overflow: 'hidden',
        }}>
          <p style={{ margin: '0', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}><strong>:</strong> {
            patient.tests_or_exams
              .replace(/Lab Test:\s*/g, '')  
              .replace(/Radiology Exam:\s*/g, '')  
          }</p>
        </div>
      </div>
    ))}
  </div>
</div>


<div className="patients-container completed-results-patients">
  <div className="container-header">
    <h2>Lab/Radiology Results that are ready and not yet seen</h2>
    <span className="patient-count">{completedResultsPatients.length}</span>
  </div>

  {/* Check if the completedResultsPatients array has any data */}
  {completedResultsPatients.length === 0 ? (
    <div style={{ padding: '20px', textAlign: 'center', fontStyle: 'italic', color: '#777' }}>
      No Unseen lab or radiology results
    </div>
  ) : (
    <div className="patients-list" style={{ display: 'flex', flexWrap: 'wrap' }}>
      {completedResultsPatients.map((patient, index) => (
        <div
          key={index}
          className="patient-card"
          style={{
            flex: '0 0 calc(33.33% - 10px)',
            margin: '5px',
            backgroundColor: '#f9f9f9',
            padding: '10px',
            border: '1px solid #ccc',
            borderRadius: '5px',
            lineHeight: '1.2',
            maxHeight: '150px',
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
          }}
          onClick={() => handlePatientClick2(patient)} // Call the handlePatientClick function on click
        >
          <p style={{ margin: '0' }}><strong>Name:</strong> {patient.name}</p>
          <p style={{ margin: '0' }}><strong>Age:</strong> {patient.age}</p>
          <p style={{ margin: '0' }}><strong>Contact:</strong> {patient.contact}</p>
          <p style={{ margin: '0' }}><strong>Sex:</strong> {patient.sex}</p>
          <p style={{ margin: '0' }}><strong>Status:</strong> {patient.status_type || 'Completed'}</p>
          <p style={{ margin: '0' }}><strong>Tests:</strong> {JSON.parse(patient.tests_or_exams).join(', ')}</p> {/* Display exams */}
        </div>
      ))}
    </div>
  )}
</div>
{isRadiologyResultsModalOpen && selectedPatient && (
  <RadiologyResultsModal3
    patient={selectedPatient} 
    onClose={() => setIsRadiologyResultsModalOpen(false)} 
    clinicDetails={clinicDetails} // Patient's data
    token={urlToken} // Pass the token to the modal
  />
)}

{isLabResultsModalOpen && selectedPatient && (
  <Resultmodal2 
    patient={selectedPatient} 
    onClose={() => setIsLabResultsModalOpen(false)} 
    clinicDetails={clinicDetails} // Patient's data
    token={urlToken} // Pass the token to the modal
  />
)}

      </div>

      <div className="buttons-container">
        <button className="doctors-room-menu-button" onClick={() => navigate(`/attend-to-new-patient?token=${urlToken}`)}>
          <FontAwesomeIcon icon={faUser} /> Attend to Non Triage Patient
        </button>
        <button className="doctors-room-menu-button" onClick={() => navigate(`/patientfiles?token=${urlToken}`)}>
          <FontAwesomeIcon icon={faUser} /> Review Existing Patient Files
        </button>
      
      </div>

      
    </div>
  );
}

export default DoctorsDashboard;

import React, { useState, useEffect, useRef } from 'react';
import { urls } from './config.dev';
import { ToastContainer, toast } from 'react-toastify'; // Import toast functions
import 'react-toastify/dist/ReactToastify.css'; // Import toast styles
import './TreatmentChatModal.css';

const cellStyle = {
    padding: '10px',
    border: '1px solid #ddd',
};

const drugNameCellStyle = {
    ...cellStyle,
    width: '40%',
};

const drugQuantityCellStyle = {
    ...cellStyle,
    width: '10%',
};

const nextDoseTimeCellStyle = {
    ...cellStyle,
    width: '25%',
};

function GiveFPmodal({ clientDetails, onClose }) {
    const [treatmentPlanRows, setTreatmentPlanRows] = useState([{ drug: '', packaging: '', quantity: '', nextDoseDate: '', nextDoseTime: '' }]);
    const [drugSuggestions, setDrugSuggestions] = useState([]);
    const [filteredSuggestions, setFilteredSuggestions] = useState([]);
    const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(null);
    const [submitting, setSubmitting] = useState(false);
    const [nextDose, setNextDose] = useState('');
    const [token, setToken] = useState(clientDetails.token); // Get token from client details
    const [fpId, setFpId] = useState(clientDetails.fp_id); // Get FP ID from client details
    const suggestionsRef = useRef(null);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [selectedDrugIndex, setSelectedDrugIndex] = useState(null);
    const quantityRefs = useRef([]);
    const [isScrolling, setIsScrolling] = useState(false);
   
    const [nextDoseDate, setNextDoseDate] = useState('');


    // Handle wheel event for scrolling
    const handleWheel = (e) => {
        setIsScrolling(true);
        e.preventDefault();
    };

    const handleWheelEnd = () => {
        setIsScrolling(false);
    };

    useEffect(() => {
        window.addEventListener('wheel', handleWheel, { passive: false });
        window.addEventListener('wheel', handleWheelEnd, { passive: false });

        return () => {
            window.removeEventListener('wheel', handleWheel);
            window.removeEventListener('wheel', handleWheelEnd);
        };
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (suggestionsRef.current && !suggestionsRef.current.contains(event.target)) {
                setShowSuggestions(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    useEffect(() => {
        const fetchDrugs = async () => {
            try {
                const response = await fetch(urls.fetchdispensary2, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ token })
                });

                if (!response.ok) throw new Error('Error fetching drugs');

                const data = await response.json();
                setDrugSuggestions(data);
            } catch (error) {
                console.error('Error fetching drugs:', error);
            }
        };
        fetchDrugs();
    }, [token]);

    const handleDrugInputChange = (index, event) => {
        const value = event.target.value;
        
        const updatedRows = [...treatmentPlanRows];
        updatedRows[index].drug = value;
        setTreatmentPlanRows(updatedRows);
    
        if (!value) {
            setFilteredSuggestions([]);
            setActiveSuggestionIndex(null);
            return;
        }
    
        const filtered = drugSuggestions.filter(drug =>
            drug.Drug.toLowerCase().includes(value.toLowerCase())
        );
        setFilteredSuggestions(filtered);
        setActiveSuggestionIndex(index);
        setShowSuggestions(true);
    };

    const handleSuggestionClick = (index, suggestion) => {
        const updatedRows = [...treatmentPlanRows];
        updatedRows[index].drug = suggestion.Drug;
        updatedRows[index].packaging = suggestion.Packaging;
        updatedRows[index].quantity = '';
        setTreatmentPlanRows(updatedRows);
        setFilteredSuggestions([]);
        setActiveSuggestionIndex(null);
        setSelectedDrugIndex(index);

        // Focus on the quantity input of the selected row
        if (quantityRefs.current[index]) {
            quantityRefs.current[index].focus();
        }
    };

    const handleDrugQuantityChange = (index, e) => {
        const { value } = e.target;
        const newQuantity = parseInt(value, 10) || 0;

        // Update the quantity in the state
        const updatedRows = [...treatmentPlanRows];
        updatedRows[index].quantity = newQuantity;
        setTreatmentPlanRows(updatedRows);

        // If Enter key is pressed
        if (e.key === 'Enter') {
            e.preventDefault(); // Prevent default Enter key behavior (form submit)
            addNewRow();
            // Focus on the "Drug Name" input in the new row
            const nextIndex = index + 1;
            if (quantityRefs.current[nextIndex]) {
                const nextInput = quantityRefs.current[nextIndex].previousElementSibling; // Get the previous sibling (Drug Name input)
                if (nextInput) {
                    nextInput.focus(); // Focus on the Drug Name input
                }
            }
        }
    };

    const addNewRow = () => {
        const lastRow = treatmentPlanRows[treatmentPlanRows.length - 1];
        
        // Check if the current row's required fields are filled
        if (!lastRow.drug || !lastRow.quantity) {
            toast.error('Please fill out the drug and quantity fields before adding a new row.');
            return; // Prevent adding a new row if fields are incomplete
        }
        
        // Add a new row
        const newRow = { drug: '', packaging: '', quantity: '', nextDoseDate: '', nextDoseTime: '' };
        setTreatmentPlanRows([...treatmentPlanRows, newRow]);

        // Focus on the "Drug Name" input in the newly created row
        // Ensure that the new row index is correct
        const nextRowIndex = treatmentPlanRows.length;
        setTimeout(() => {
            const newDrugInput = document.querySelector(`.treatment-chat-table tr:nth-child(${nextRowIndex + 2}) .drug-chat-input`);
            if (newDrugInput) {
                newDrugInput.focus();
            }
        }, 0); // Use setTimeout to ensure the new row is rendered before trying to focus
    };

    const removeRow = (index) => {
        if (treatmentPlanRows.length > 1) {
            const updatedRows = treatmentPlanRows.filter((_, i) => i !== index);
            setTreatmentPlanRows(updatedRows);
        }
    };

    const handleTreatmentPlanSubmit = async () => {
        let errorMessage = '';
        
        // Show initial toast message to inform user
        toast.info(`Hello ${clientDetails.first_name}, please wait, we are submitting your family planning drugs.`);
        
        // Wait for 2 seconds before proceeding with the rest of the function
        await new Promise(resolve => setTimeout(resolve, 2000));
        
        // Check if all table cells are filled
        const incompleteRows = treatmentPlanRows.filter(row =>
            !row.drug || !row.quantity
        );
        
        if (incompleteRows.length > 0) {
            if (errorMessage) errorMessage += ' ';
            errorMessage += 'Some rows are incomplete. Please fill out all drug and quantity fields.';
        }
        
        // Ensure expiry date is filled if "On a specific date" is selected
        if (nextDose === 'On a specific date' && !nextDoseDate) {
            if (errorMessage) errorMessage += ' ';
            errorMessage += 'Please select the next appointment date.';
        }
        
        if (errorMessage) {
            toast.error(errorMessage, { autoClose: 60000 }); // Toast duration set to 60 seconds
            return;
        }
        
        setSubmitting(true);
        
        // Include the token, FP ID, and expiry date in the payload
        const payload = {
            fp_id: clientDetails.fp_id, // Family Planning ID
            first_name: clientDetails.first_name, // Client first name
            last_name: clientDetails.last_name.trim(), // Client last name (trimmed to remove extra spaces)
            full_name: `${clientDetails.first_name.trim()} ${clientDetails.last_name.trim()}`,
            age: clientDetails.age, // Client age
            sex: clientDetails.sex, // Client sex
            phone_number: clientDetails.phone_number, // Client phone number
            method: clientDetails.method, // Family Planning method
            treatment_plan: treatmentPlanRows, // Treatment plan details
            token, // Add token here
            next_appointment_date: nextDose === 'On a specific date' ? nextDoseDate : null, // Add next appointment date
        };
        
        try {
            const response = await fetch(urls.submitFPdrugs, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(payload),
            });
        
            const data = await response.json(); // Parse JSON body
        
            if (!response.ok) {
                // If response is not ok, throw the error message from the response
                const backendErrorMessage = data.error || 'Something went wrong.';
                throw new Error(backendErrorMessage);
            }
        
            if (data.success) {
                // Wait for 2 seconds before showing success toast
                setTimeout(() => {
                    toast.success('Success! Family planning drugs submitted.');
                    // Delay closing the modal by 5 seconds
                    setTimeout(() => {
                        onClose();
                    }, 5000);
                }, 2000);
            }
        } catch (error) {
            console.error('Error submitting treatment plan:', error);
            // Wait for 2 seconds before showing the actual error toast
            setTimeout(() => {
                toast.error(`Error: ${error.message}`);
            }, 2000);
        } finally {
            setSubmitting(false);
        }
    };
    
    return (
        <div
            className="treatment-chat-container"
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'fixed',
                top: 60,
                left: 260,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(0, 0, 0, 0.5)', // Optional: adds a backdrop
                zIndex: 1000 // Ensure modal is on top
            }}
        >
            <div
                style={{
                    backgroundColor: 'white',
                    borderRadius: '8px',
                    padding: '20px',
                   
                    width: '1060%', // Full width of the screen
                    maxWidth: '3000px', // Optional: max width for better appearance on larger screens
                    overflow: 'auto', // Handle overflow
                    margin: '0 auto', // Center horizontally
                }}
            >
                <ToastContainer />
                <h3 style={{ textAlign: 'center' }} className="treatment-chat-header">
  Record Drugs You Have Used During Family Planning Provision
</h3>

             
                <p><strong>Client Name:</strong> {clientDetails.first_name} {clientDetails.last_name}</p>
                <p><strong>Age:</strong> {clientDetails.age}</p>
                <p><strong>Sex:</strong> {clientDetails.sex}</p>
                <p><strong>Phone Number:</strong> {clientDetails.phone_number}</p>
                <p><strong>Family Planning Method Given:</strong> {clientDetails.method}</p>
             
                <div>
                    <p style={{ fontSize: 'small', color: 'red', marginBottom: '13px' }}>
                        Please chat everything you have given to this patient, however small, be it a syringe or cannula, to enable the system to make more accurate calculations.
                    </p>
                </div>
                <table className="treatment-chat-table">
                    <thead>
                        <tr>
                            <th style={drugNameCellStyle}>Drug Name</th>
                            <th style={cellStyle}>Packaging</th>
                            <th style={drugQuantityCellStyle}>Quantity</th>
                            <th style={cellStyle}>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {treatmentPlanRows.map((row, index) => {
                            const isDrugSelected = index === selectedDrugIndex;
                            const quantityInputStyle = {
                                pointerEvents: isDrugSelected ? 'auto' : 'none',
                                opacity: isDrugSelected ? 1 : 0.8,
                            };
    
                            return (
                                <tr key={index}>
                                    <td style={{ position: 'relative' }}>
                                        <input
                                            type="text"
                                            className="drug-chat-input"
                                            value={row.drug}
                                            onChange={e => handleDrugInputChange(index, e)}
                                            onFocus={() => setShowSuggestions(true)}
                                        />
                                        {index === activeSuggestionIndex && filteredSuggestions.length > 0 && showSuggestions && (
                                            <div className="drug-chat-suggestions-container" ref={suggestionsRef}>
                                                <ul className="drug-chat-suggestions-list">
                                                    {filteredSuggestions.map((suggestion, i) => (
                                                        <li key={i} onClick={() => handleSuggestionClick(index, suggestion)}>
                                                            {suggestion.Drug} - {suggestion.Packaging} (Available: {suggestion.Quantity})
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        )}
                                    </td>
                                    <td>{row.packaging}</td>
                                    <td>
                                        <input
                                            type="number"
                                            className={`quantity-input ${isScrolling ? 'scrolling' : ''}`}
                                            value={row.quantity}
                                            onChange={e => handleDrugQuantityChange(index, e)}
                                            onKeyDown={e => handleDrugQuantityChange(index, e)} // Ensure this handler is properly implemented
                                            style={quantityInputStyle}
                                            ref={el => quantityRefs.current[index] = el} // Assign ref
                                        />
                                    </td>
                                    <td>
                                        <button
                                            type="button"
                                            className="add-row-button"
                                            onClick={addNewRow}
                                            disabled={treatmentPlanRows.length >= 10} // Optional limit for rows
                                        >
                                            +
                                        </button>
                                        <button
                                            type="button"
                                            className="remove-row-button"
                                            onClick={() => removeRow(index)}
                                        >
                                            x
                                        </button>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                {/* Expiry date section */}
                <div className="expiry-container">
                <h3 style={{ fontSize: '14px' }}>
  If the family planning method given has an expiry date, please select the next appointment date for this client
</h3>

                    <label>
                        <input
                            type="radio"
                            name="Expiry date"
                            value="On a specific date"
                            checked={nextDose === 'On a specific date'}
                            onChange={() => setNextDose('On a specific date')}
                        />
                        On a specific date
                    </label>
                    {nextDose === 'On a specific date' && (
                        <div className="date-time-inputs">
                            <label>
                                Date:
                                <input
                                    type="date"
                                    value={nextDoseDate}
                                    onChange={(e) => setNextDoseDate(e.target.value)}
                                />
                            </label>
                        </div>
                    )}
                </div>
    
                <div className="submit-container">
                    <button
                        type="button"
                        onClick={handleTreatmentPlanSubmit}
                        disabled={submitting}
                        className="treatment-chat-submit-button"
                    >
                        {submitting ? 'Submitting...' : 'Submit Chat'}
                    </button>
                    <button type="button" className="treatment-chat-close-button" onClick={onClose}>
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    );
    
}

export default GiveFPmodal;

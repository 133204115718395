import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Dashboard from './dashboard';
import Sales from './Sales';
import Store from './Store';
import Selldrugs from './selldrugs';
import GetDrugs from './GetDrugs';
import Contacts from './Contacts';
import AdminDashboard from './AdminDashboard';
import Salesdetails from './Salesdetails';
import Patientappointments from './Patientappointments';
import Statistics from './Statistics';
import Credits from './Credits';
import BirthdayPage from './BirthdayPage';
import DispensedSoldDrugs from './DispensedSoldDrugs';
import TakenFromShelf from './TakenFromShelf';
import DoctorsRoom from './DoctorsRoom';
import DoctorsDashboard from './DoctorsDashboard';
import Cashier from './Cashier';
import AwaitingPayments from './AwaitingPayments';
import LabDashboard from './LabDashboard';
import Laboratory from './Laboratory';
import PatientFiles from './PatientFiles';
import PatientFiles2 from './PatientFiles2';
import RadiographerDashboard from './RadiographerDashboard';
import Radiology from './Radiology';
import ViewFile from './ViewFile';
import DischargeForm from './DischargeForm';
import ReferralForm from './ReferralForm';
import DrugOrigins from './DrugOrigins';
import GenerateDrugOrder from './GenerateDrugOrder';
import NurseDashboard from './NurseDashboard';
import Investigations from './Investigations';
import Viewfiles from './Viewfiles';
import ClinicRegistration1 from './ClinicRegistration1';
import ClinicRegistration from './ClinicRegistration';
import Login from './Login';
import Performance from './Performance';
import Malariagraph from './Malariagraph';
import Payment from './Payment';
import AboutUs from './AboutUs';
import SetSurvey from './SetSurvey';
import Triage from './Triage';
import MaternityDashboard from './MaternityDashboard';
import Mothers from './Mothers';
import FamilyPlanningMethods from './FamilyPlanningMethods'; 

function App() {
  return (
    <Router>
      <Routes>
      <Route path="/" element={<Login/>} />
      <Route path="/login" element={<Login/>} />
      <Route path="/clinic-registration1" element={<ClinicRegistration1/>} />
      <Route path="/clinic-registration" element={<ClinicRegistration/>} />
      <Route path="/aboutus" element={<AboutUs/>} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/sales" element={<Cashier />} />
      <Route path="/salespage" element={<Sales />} />
        <Route path="/store" element={<Store />} />
        <Route path="/selldrugs" element={<Selldrugs />} />
        <Route path="/dispensary/shelves" element={<Selldrugs />} />
        <Route path="/remove-drugs" element={<GetDrugs />} />
        <Route path="/contacts/:employee" element={<Contacts />} />
        <Route path="/admin-dashboard" element={<AdminDashboard />} />
        <Route path="/access-sales-details"element={<Salesdetails />} />
        <Route path="contacts" element={<Contacts />} />
        <Route path="/birthdays/:employee" element={<BirthdayPage />} />
        <Route path="/patient-appointments" element={<Patientappointments />} />
        <Route path="patient-appointments" element={<Patientappointments />} />
        <Route path="clinicStatistics"element={<Statistics />} />
        <Route path="/credits" element={<Credits />} />
        <Route path="/setsurvey" element={<SetSurvey />} />
        <Route path="birthdays" element={<BirthdayPage />} />
        <Route path="dispensed-and-sold"  element={<DispensedSoldDrugs />} />
        <Route path="removed-drugs-equipment"  element={<TakenFromShelf />} />
        <Route path="/attend-to-new-patient" element={<DoctorsRoom />} />
        <Route path="/access-doctors-room" element={<DoctorsDashboard />} />
        <Route path="/cashier" element={<Cashier />} />
        <Route path="/awaitingpayments" element={<AwaitingPayments />} />
        <Route path="/access-laboratory" element={<LabDashboard />} />
        <Route path="/lab" element={<Laboratory />} />
        <Route path="/patientfiles" element={<PatientFiles />} />
        <Route path="/patientfiles2" element={<PatientFiles2 />} />
        <Route path="/patient-file/:token/:fileId" element={<ViewFile />} />
        <Route path="/patient-file2/:token/:fileId" element={<Viewfiles />} />
        <Route path="/access-radiographer" element={<RadiographerDashboard/>} />
        <Route path="/radiology" element={<Radiology/>} />
        <Route path="/discharge-form/:employeeName/:fileId" element={<DischargeForm />} />
        <Route path="/referral-form/:employeeName/:fileId" element={< ReferralForm/>} />
        <Route path="/manageDrugs" element={<DrugOrigins/>} />
        <Route path="/makeOrderForDrugs" element={<GenerateDrugOrder/>} />
        <Route path="/access-nurse" element={<NurseDashboard/>} />
        <Route path="/manageLaboratory" element={<Investigations/>} />
        <Route path="/clinic-registration" element={<ClinicRegistration/>} />
        <Route path="/employeePerformance" element={<Performance/>} />
        <Route path="/malariaBarGraph" element={<Malariagraph/>} />
        <Route path="/makePayment" element={<Payment/>} />
        <Route path="/triage" element={<Triage/>} />
        <Route path="/familyPlanning" element={<FamilyPlanningMethods />} />
        <Route path="/maternity-dashboard" element={<MaternityDashboard />} />
      
        <Route path="/mothers" element={<Mothers />} />
        </Routes>
    </Router>
  );
}

export default App;

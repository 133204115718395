import React, { useState, useEffect } from 'react';
import { API_URL, urls } from './config.dev';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import Topbar from './Topbar'; // Import the Topbar component
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function Sales() {
  const [currentTime, setCurrentTime] = useState(new Date());
  const [selectedShift, setSelectedShift] = useState(null);
  const [shiftDate, setShiftDate] = useState(null);
  const [salesData, setSalesData] = useState([]);
  const [expensesData, setExpensesData] = useState([]);
  const [showSalesTable, setShowSalesTable] = useState(true);
  const [comments, setComments] = useState('');
  const [newSale, setNewSale] = useState({ amount: 0, reason: '', servedBy: '' });
  const [newExpense, setNewExpense] = useState({ amount: 0, details: '', takenBy: '', servedBy: '' });
  const [totalSales, setTotalSales] = useState(0);
  const [totalExpenses, setTotalExpenses] = useState(0);
  const [cashAtHand, setCashAtHand] = useState(0);
  const [isAddingSale, setIsAddingSale] = useState(false);
  const [isAddingExpense, setIsAddingExpense] = useState(false);
  const [isClosingShift, setIsClosingShift] = useState(false);
  const [employee, setEmployee] = useState(''); // State for employee name
  const [deletingSaleId, setDeletingSaleId] = useState(null);
  const [deletingExpenseId, setDeletingExpenseId] = useState(null);
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const urlToken = params.get('token'); // Define urlToken here
  const [formattedDate, setFormattedDate] = useState('');

  useEffect(() => {
    // Function to update current time every second
    const updateTime = () => {
      setCurrentTime(new Date()); // Update currentTime state with the current time
    };

    // Call updateTime immediately when the component mounts
    updateTime();

    // Set up interval to update currentTime every second
    const intervalId = setInterval(updateTime, 1000);

    // Clean up interval on component unmount
    return () => clearInterval(intervalId);
  }, []); // Empty dependency array ensures this effect runs only once on component mount

  // Function to check if current time is within specified range
  function isTimeInRange(startHour, startMinute, endHour, endMinute) {
    const now = new Date();
    const start = new Date(now.getFullYear(), now.getMonth(), now.getDate(), startHour, startMinute);
    const end = new Date(now.getFullYear(), now.getMonth(), now.getDate(), endHour, endMinute);

    return now >= start && now <= end;
  }

  // Function to refresh the page after a specified delay with animation
  function refreshPageWithMessage(message, delaySeconds) {
    // Show loading animation or message
    document.body.innerHTML = `
      <div style="text-align: center; font-size: 24px; padding: 20px;">
          <span id="loadingText">${message}</span>
          <div id="loadingAnimation"></div>
      </div>
    `;

    // Function to update loading animation (optional)
    function updateLoadingAnimation() {
      const loadingAnimation = document.getElementById('loadingAnimation');
      // Replace with your own loading animation logic
      loadingAnimation.innerHTML = '<i class="faSpinner"></i>'; // Example using Font Awesome spinner
    }

    // Update loading animation (optional)
    updateLoadingAnimation();

    // Set timeout to refresh the page after delay
    setTimeout(() => {
      window.location.reload(); // Refresh the page using window.location
    }, delaySeconds * 1000); // Convert seconds to milliseconds
  }

// Check for evening time range (11:00 PM to 11:01 PM)
if (isTimeInRange(23, 0, 23, 1)) {
  refreshPageWithMessage("Clinic pro checking shift validity... This might take up to one minute.", 60); // Refresh after 60 seconds
}

// Check for morning time range (11:00 AM to 11:01 AM)
if (isTimeInRange(11, 0, 11, 1)) {
  refreshPageWithMessage("Clinic pro checking shift validity... This might take up to one minute.", 60); // Refresh after 60 seconds
}


  useEffect(() => {
    const fetchTokenAndCheckSecurity = async () => {
      try {
        const params = new URLSearchParams(window.location.search);
        const tokenFromUrl = params.get('token');

        // Check if tokenFromUrl exists
        if (!tokenFromUrl) {
          console.error('Token not found in URL');
          navigate('/login');
          return;
        }

        // Send token to security backend
        const securityResponse = await fetch(urls.security, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ token: tokenFromUrl }), // Include token in the body
        });

        if (securityResponse.ok) {
          const securityData = await securityResponse.json();

          if (securityData.message === 'Session valid') {
            setEmployee(securityData.employee_name); // Set employee name from API response

            // Proceed to fetch selected shift with token included
            fetchShiftData(tokenFromUrl);

            // Continue with other state setting based on securityData

          } else if (securityData.error === 'Session expired') {
            navigate('/login');
          } else {
            navigate('/login');
          }
        } else {
          throw new Error('Failed to perform security check');
        }
      } catch (error) {
        console.error('Error performing security check:', error);
        navigate('/login');
      }
    };

    const fetchShiftData = async (token) => {
      try {
        const body = JSON.stringify({ token: token }); // Include token in the request body

        console.log('Request Body:', body); // Log body for debugging

        const shiftResponse = await fetch(urls.checkshift, {
          method: 'POST', // Use POST method instead of GET
          headers: {
            'Content-Type': 'application/json',
          },
          body: body, // Pass the body with token
        });

        if (shiftResponse.ok) {
          const shiftData = await shiftResponse.json();
          if (shiftData.shiftType) {
            setSelectedShift(shiftData.shiftType); // Set the selected shift based on the response
            setShiftDate(shiftData.shiftDate); // Set the shift date based on the response
          } else {
            throw new Error('Failed to fetch selected shift');
          }
        } else {
          throw new Error('Failed to fetch selected shift');
        }
      } catch (error) {
        console.error('Error fetching selected shift:', error);
      }
    };

    fetchTokenAndCheckSecurity();
  }, [navigate]);

  useEffect(() => {
    if (selectedShift && shiftDate) {
      fetchSalesData(selectedShift, shiftDate);
      fetchExpensesData(selectedShift, shiftDate);
    }
  }, [selectedShift, shiftDate]);

  useEffect(() => {
    // Calculate total sales
    const salesTotal = salesData.reduce((total, sale) => total + parseFloat(sale.amount), 0);
    setTotalSales(salesTotal);

    // Calculate total expenses
    const expensesTotal = expensesData.reduce((total, expense) => total + parseFloat(expense.amount), 0);
    setTotalExpenses(expensesTotal);

    // Calculate cash at hand
    const cashAtHand = salesTotal - expensesTotal;
    setCashAtHand(cashAtHand);
  }, [salesData, expensesData]);

  const fetchSalesData = (shift, shiftDate) => {
    try {
      const params = new URLSearchParams(window.location.search);
      const tokenFromUrl = params.get('token');

      if (!tokenFromUrl) {
        console.error('Token not found in URL');
        return;
      }

      const url = `${API_URL}/fetchsales.php`;

      const payload = {
        date: shiftDate,
        shift: shift,
        token: tokenFromUrl
      };

      fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      })
        .then(response => {
          if (!response.ok) {
            throw new Error('Failed to fetch sales data');
          }
          return response.json();
        })
        .then(data => {
          const formattedData = data.map(sale => ({
            amount: sale.Amount,
            reason: sale.Reason,
            servedBy: sale.ServedBy,
            id: sale.SaleID
          }));

          setSalesData(formattedData);
        })
        .catch(error => {
          console.error('Error fetching sales data:', error);
        });
    } catch (error) {
      console.error('Error in fetchSalesData:', error);
    }
  };

  const fetchExpensesData = (shift, shiftDate) => {
    try {
      const params = new URLSearchParams(window.location.search);
      const tokenFromUrl = params.get('token');

      if (!tokenFromUrl) {
        console.error('Token not found in URL');
        return;
      }

      const url = `${API_URL}/fetchexpenses.php`;

      const payload = {
        date: shiftDate,
        shift: shift,
        token: tokenFromUrl
      };

      fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      })
        .then(response => {
          if (!response.ok) {
            throw new Error('Failed to fetch expenses data');
          }
          return response.json();
        })
        .then(data => {
          const formattedData = data.map(expense => ({
            amount: expense.Amount,
            details: expense.Details,
            takenBy: expense.TakenBy,
            servedBy: expense.ServedBy,
            id: expense.ExpenseID
          }));

          setExpensesData(formattedData);
        })
        .catch(error => {
          console.error('Error fetching expenses data:', error);
        });
    } catch (error) {
      console.error('Error in fetchExpensesData:', error);
    }
  };
  useEffect(() => {
    if (selectedShift && shiftDate) {
      // Fetch sales and expenses data immediately once when the shift and date are set
      fetchSalesData(selectedShift, shiftDate);
      fetchExpensesData(selectedShift, shiftDate);

      // Set up interval to fetch data every 5 seconds
      const intervalId = setInterval(() => {
        fetchSalesData(selectedShift, shiftDate);
        fetchExpensesData(selectedShift, shiftDate);
      }, 15000);

      // Clear the interval on component unmount or when selectedShift or shiftDate change
      return () => clearInterval(intervalId);
    }
  }, [selectedShift, shiftDate]);

  const handleAddExpense = () => {
    setIsAddingExpense(true); // Set loading state to true
  
    try {
      const params = new URLSearchParams(window.location.search);
      const tokenFromUrl = params.get('token');
  
      if (!tokenFromUrl) {
        toast.error('Token not found in URL');
        setIsAddingExpense(false); // Set loading state to false
        return;
      }
  
      const amountValid = /^-?\d+(\.\d+)?$/.test(newExpense.amount); // Check if amount is valid number with optional decimal and can be negative
      if (!amountValid) {
        toast.error('Invalid amount format. Please enter a valid number.');
        setIsAddingExpense(false); // Set loading state to false
        return;
      }
  
      const requestData = {
        amount: newExpense.amount,
        details: newExpense.details,
        takenBy: newExpense.takenBy,
        servedBy: employee,
        shift: selectedShift,
        date: currentTime.toISOString().split('T')[0],
        token: tokenFromUrl // Include token in the request data
      };
  
      // Validation: Ensure all required fields are provided
      const missingFields = [];
      for (const key in requestData) {
        if (!requestData[key]) {
          missingFields.push(key);
        }
      }
  
      if (missingFields.length > 0) {
        toast.error(`Missing fields: ${missingFields.join(', ')}`);
        setIsAddingExpense(false); // Set loading state to false
        return;
      }
  
      fetch(urls.expenses, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestData),
      })
        .then(response => response.json())
        .then(data => {
          const emptyNewExpense = { amount: 0, details: '', takenBy: '', servedBy: employee };
          setNewExpense(emptyNewExpense);
          setExpensesData([...expensesData, requestData]);
          toast.success('Expense added successfully!');
        })
        .catch(error => {
          toast.error('Error adding expense.');
        })
        .finally(() => {
          setIsAddingExpense(false); // Set loading state to false
        });
    } catch (error) {
      toast.error('Error fetching token from URL.');
      setIsAddingExpense(false); // Set loading state to false on error
    }
  };
  
  const handleAddSale = () => {
    setIsAddingSale(true); // Set loading state to true
  
    try {
      const params = new URLSearchParams(window.location.search);
      const tokenFromUrl = params.get('token');
  
      if (!tokenFromUrl) {
        toast.error('Token not found in URL');
        setIsAddingSale(false); // Set loading state to false
        return;
      }
  
      const amountValid = /^-?\d+(\.\d+)?$/.test(newSale.amount); // Check if amount is a valid number with optional decimal
      if (!amountValid) {
        toast.error('Invalid amount format. Please enter a valid number.');
        setIsAddingSale(false); // Set loading state to false
        return;
      }
  
      const requestData = {
        amount: newSale.amount,
        reason: newSale.reason,
        servedBy: employee,
        shift: selectedShift,
        date: currentTime.toISOString().split('T')[0],
        token: tokenFromUrl, // Include token in the request data
      };
  
      // Validation: Ensure all required fields are provided
      if (
        !requestData.amount ||
        !requestData.reason ||
        !requestData.servedBy ||
        !requestData.shift ||
        !requestData.date
      ) {
        toast.error('All fields must be filled out');
        setIsAddingSale(false); // Set loading state to false
        return;
      }
  
      fetch(urls.sales, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestData),
      })
        .then((response) => response.json())
        .then((data) => {
          const emptyNewSale = { amount: 0, reason: '', servedBy: employee };
          setNewSale(emptyNewSale);
          setSalesData([...salesData, requestData]);
          toast.success('Sale added successfully!');
        })
        .catch((error) => {
          toast.error(`Error adding sale: ${error.message}`);
        })
        .finally(() => {
          setIsAddingSale(false); // Set loading state to false
        });
    } catch (error) {
      toast.error(`Error fetching token from URL: ${error.message}`);
      setIsAddingSale(false); // Set loading state to false on error
    }
  };
  
  const handleDeleteSale = (id) => {
    setDeletingSaleId(id); // Set loading state for the specific sale
    const saleToDelete = salesData.find(sale => sale.id === id);

    if (saleToDelete) {
        const { amount, reason, servedBy } = saleToDelete;

        // Fetch the token from the URL
        const params = new URLSearchParams(window.location.search);
        const tokenFromUrl = params.get('token');

        const payload = {
            amount,
            reason,
            servedBy,
            shift: selectedShift,
            date: new Date().toISOString().split('T')[0],
            token: tokenFromUrl // Add token to the payload
        };

        fetch(urls.deletesale, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        })
            .then(response => {
                if (response.ok) {
                    setSalesData(prevSalesData => prevSalesData.filter(sale => sale.id !== id));
                    toast.success("Sale deleted successfully");
                } else {
                    return response.json().then(err => {
                        if (err.error && err.error.includes("You are not authorized to delete a sale")) {
                            // Extract the contact name if present after "Please contact:"
                            const contactPart = err.error.split('Please contact:')[1]?.trim();
                            const contactNames = contactPart ? contactPart.split(' or ')[0].trim() : null;

                            // If no contact names are provided, default to "the administrator"
                            const contactInfo = contactNames && contactNames.length > 0
                                ? contactNames
                                : "";

                            // Show a polite message including the contact names or default administrator
                            toast.error(`Sorry, you are not authorized to delete  sales from the sales page. Please contact ${contactInfo} or request delete sale permissions from the administrator.`, {
                                autoClose: 60000, // Show the error for 1 minute
                            });
                        } else {
                            // Generic error handling
                            toast.error(`Error: ${err.message || 'Failed to delete sale'}`, {
                                autoClose: false, // Keep it open until manually closed
                            });
                        }
                    });
                }
            })
            .catch(error => {
                console.error('Error:', error);
                toast.error(`Error: ${error.message || 'Failed to delete sale'}`, {
                    autoClose: 60000, // Show the error for 1 minute
                });
            })
            .finally(() => {
                setDeletingSaleId(null); // Reset loading state
            });
    } else {
        console.error('Sale not found');
    }
};

const handleDeleteExpense = (id) => {
  setDeletingExpenseId(id); // Set loading state for the specific expense
  const expenseToDelete = expensesData.find(expense => expense.id === id);

  if (expenseToDelete) {
      const { amount, details, takenBy, servedBy } = expenseToDelete;
      const payload = {
          amount,
          details,
          takenBy,
          servedBy,
          shift: selectedShift,
          date: new Date().toISOString().split('T')[0]
      };

      fetch(urls.deleteexpense, {
          method: 'DELETE',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
      })
          .then(response => {
              if (response.ok) {
                  setExpensesData(prevExpenses => prevExpenses.filter(expense => expense.id !== id));
                  toast.success("Expense deleted successfully"); // Success toast
              } else {
                  throw new Error('Failed to delete expense');
              }
          })
          .catch(error => {
              console.error('Error:', error);
              toast.error('Error deleting expense'); // Error toast
          })
          .finally(() => {
              setDeletingExpenseId(null); // Reset loading state
          });
  } else {
      console.error('Expense not found');
      toast.error('Expense not found'); // Error toast if expense not found
  }
};

  const handleCloseShift = () => {
    // Check if there is something in the comments section
    if (!comments.trim()) {
      alert("Please enter comments before closing the shift.");
      return;
    }
  
    // Ask for confirmation before closing the shift
    const confirmClose = window.confirm("Are you sure you want to close this shift?");
    if (!confirmClose) {
      return; // If user cancels, do nothing
    }
  
    setIsClosingShift(true);
  
    try {
      const params = new URLSearchParams(window.location.search);
      const tokenFromUrl = params.get('token');
  
      if (!tokenFromUrl) {
        console.error('Token not found in URL');
        return;
      }
  
      // Prepare data to send to the backend
      const requestData = {
        comments: comments,
        shift: selectedShift, // Include shift type in the payload
        employee: employee, // Assuming `employee` is available in the component scope
        token: tokenFromUrl, // Include token from URL
        totalSales: totalSales, // Include total sales
        totalExpenses: totalExpenses // Include total expenses
      };
  
      // Make the API call to close the shift
      fetch(urls.closeshift, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestData),
      })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('Failed to close shift');
        }
      })
      .then(data => {
        console.log('Shift closed successfully:', data);
        // Redirect to the dashboard after successfully closing the shift
        navigate('/');
      })
      .catch(error => {
        console.error('Error closing shift:', error);
      })
      .finally(() => {
        setIsClosingShift(false);
      });
    } catch (error) {
      console.error('Error fetching token from URL:', error);
      setIsClosingShift(false); // Set loading state to false on error
    }
  };
  
  useEffect(() => {
    // Function to format the shiftDate from the backend
    const formatShiftDate = (dateStr) => {
      const date = new Date(dateStr);
      const formatter = new Intl.DateTimeFormat('en-GB', {
        weekday: 'long',  // Day of the week
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        timeZone: 'Africa/Kampala',
      });
      return formatter.format(date);
    };

    // If shiftDate is available, format it
    if (shiftDate) {
      setFormattedDate(formatShiftDate(shiftDate));
    }
  }, [shiftDate]);
  
  return (
    <div className="sales-container">
         <Topbar token={urlToken} />
         <ToastContainer />
      <div className="sales-header">
        <div className="sales-employee-name">{employee}</div>
        <div className="sales-current-time">
          {currentTime.toLocaleString('en-US', { timeZone: 'Africa/Kampala' })}
        </div>
      </div>

      {!selectedShift && (
        <div className="shift-selection">
          {/* Shift selection logic */}
        </div>
      )}

      {selectedShift && (
        <>
          <div className="toggle-button">
            <button onClick={() => {
              setShowSalesTable(!showSalesTable);
              if (showSalesTable) {
                fetchExpensesData(selectedShift);
              } else {
                fetchSalesData(selectedShift);
              }
            }}>
             
              {showSalesTable ? 'Switch to Expenses' : 'Switch to Sales'}
            </button>
          </div>

          {showSalesTable ? (
            <div className="sales-table-container">
              <div
      className="sales-table-header"
      style={{
        fontSize: '36px', // Adjust font size as needed
        fontWeight: 'bold',
        color: '#333',
        padding: '10px',
        backgroundColor: '#f4f4f4',
        borderBottom: '2px solid #ddd',
        borderRadius: '5px 5px 0 0',
        textAlign: 'center', // Center the text
        width: '100%', // Ensure the header spans the full width of the container
      }}
    >
      {formattedDate} - {selectedShift} Shift Sales
    </div>

              <table className="sales-table">
                <thead>
                  <tr>
                    <th>Amount</th>
                    <th>Reason</th>
                    <th>Served By</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {salesData.map((sale, index) => (
                    <tr key={index}>
                      <td>{sale.amount}</td>
                      <td>{sale.reason}</td>
                      <td>{sale.servedBy}</td>
                      <td className="sales-table-actions">
                        {index !== 0 && (
                          <button
                            className="sales-delete-button"
                            onClick={() => handleDeleteSale(sale.id)}
                            disabled={deletingSaleId === sale.id}
                          >
                            {deletingSaleId === sale.id ? <FontAwesomeIcon icon={faSpinner} spin /> : 'Delete Sale'}
                          </button>
                        )}
                      </td>
                    </tr>
                  ))}
                  <tr>
                    <td>
                      <input
                        type="text"
                        className="input-yellow-border"
                        style={{ width: '80px', height: '30px', fontSize: '16px' }}
                        value={newSale.amount}
                        onChange={(e) => setNewSale({ ...newSale, amount: e.target.value })}
                        inputMode="numeric"
                        pattern="[0-9]*"
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        className="input-yellow-border"
                        style={{ width: '150px', height: '30px', fontSize: '16px' }}
                        value={newSale.reason}
                        onChange={(e) => setNewSale({ ...newSale, reason: e.target.value })}
                      />
                    </td>
                    <td>{employee}</td>
                    <td>
                      <button className="sales-add-button" onClick={handleAddSale} disabled={isAddingSale}>
                        {isAddingSale ? <FontAwesomeIcon icon={faSpinner} spin /> : 'Add Sale'}
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          ) : (
            <div className="expenses-table-container">
             <div
  className="expenses-table-header"
  style={{
    fontSize: '36px', // Adjust font size as needed
    fontWeight: 'bold',
    color: '#333',
    padding: '10px',
    backgroundColor: '#f4f4f4',
    borderBottom: '2px solid #ddd',
    borderRadius: '5px 5px 0 0',
    textAlign: 'center', // Center the text
    width: '100%', // Ensure the header spans the full width of the container
  }}
>
  {formattedDate} - {selectedShift} Shift Expenses
</div>

              <table className="expenses-table">
                <thead>
                  <tr>
                    <th>Amount</th>
                    <th>Details</th>
                    <th>Taken By</th>
                    <th>Served By</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {expensesData.map((expense, index) => (
                    <tr key={index}>
                      <td>{expense.amount}</td>
                      <td>{expense.details}</td>
                      <td>{expense.takenBy}</td>
                      <td>{expense.servedBy}</td>
                      <td className="expenses-table-actions">
                        <div className="expenses-delete-button-container">
                          {index !== 0 && (
                            <button
                              className="expenses-delete-button"
                              onClick={() => handleDeleteExpense(expense.id)}
                              disabled={deletingExpenseId === expense.id}
                            >
                              {deletingExpenseId === expense.id ? <FontAwesomeIcon icon={faSpinner} spin /> : 'Delete Expense'}
                            </button>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
                  <tr>
                    <td>
                      <input
                        type="text"
                        className="input-yellow-border"
                        style={{ width: '80px', height: '30px', fontSize: '16px' }}
                        value={newExpense.amount}
                        onChange={(e) => setNewExpense({ ...newExpense, amount: e.target.value })}
                        inputMode="numeric"
                        pattern="[0-9]*"
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        className="input-yellow-border"
                        style={{ width: '150px', height: '30px', fontSize: '16px' }}
                        value={newExpense.details}
                        onChange={(e) => setNewExpense({ ...newExpense, details: e.target.value })}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        className="input-yellow-border"
                        style={{ width: '120px', height: '30px', fontSize: '16px' }}
                        value={newExpense.takenBy}
                        onChange={(e) => setNewExpense({ ...newExpense, takenBy: e.target.value })}
                      />
                    </td>
                    <td>{employee}</td>
                    <td>
                      <button className="expenses-add-button" onClick={handleAddExpense} disabled={isAddingExpense}>
                        {isAddingExpense ? <FontAwesomeIcon icon={faSpinner} spin /> : 'Add Expense'}
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}
        </>
      )}

      <div className="totals-section">
        <div>Total Sales: {totalSales}</div>
        <div>Total Expenses: {totalExpenses}</div>
        <div>Cash at Hand: {cashAtHand}</div>
      </div>

      <div className="comments-section">
        <textarea
          value={comments}
          onChange={(e) => setComments(e.target.value)}
          placeholder="Enter comments about the shift..."
        />
      </div>

      <button className="close-shift-button" onClick={handleCloseShift} disabled={isClosingShift}>
        {isClosingShift ? 'Closing shift, Please wait...' : 'Close Shift'}
      </button>
      <footer className="footer2">
            This system was created by DeepMind E-Systems. For support or help contact +256786747733
          </footer>

    </div>
    
  );
};

export default Sales;
import React, { useEffect, useState } from 'react';

function NetworkStrength() {
    const [ping, setPing] = useState(null); // State to hold ping time
    const [networkType, setNetworkType] = useState(''); // State to hold network type
    const [isVisible, setIsVisible] = useState(true); // State to control visibility
    const [isHovered, setIsHovered] = useState(false); // State to control tooltip visibility

    useEffect(() => {
        const updateNetworkInfo = () => {
            if (navigator.connection) {
                const { effectiveType } = navigator.connection;
                setNetworkType(effectiveType); // Set the network type

                // Check for internet connectivity
                if (!navigator.onLine) {
                    setPing(null); // Reset ping on offline status
                }
            }
        };

        const checkPing = async () => {
            // Only check ping if online
            if (navigator.onLine) {
                const startTime = performance.now();
                try {
                    await fetch('https://www.google.com', { method: 'HEAD', mode: 'no-cors' });
                    const endTime = performance.now();
                    setPing(Math.round(endTime - startTime)); // Set ping in ms
                } catch (error) {
                    setPing(null); // If there's an error, set ping to null
                }
            } else {
                setPing(null); // Reset ping if offline
            }
        };

        // Check initial network info
        updateNetworkInfo();

        // Set interval to check network info and ping every 5 seconds
        const intervalId = setInterval(() => {
            updateNetworkInfo();
            checkPing(); // Check ping at the same time
        }, 5000);

        // Clean up interval on component unmount
        return () => clearInterval(intervalId);
    }, []);

    // Determine number of bars based on ping
    const getSignalStrength = () => {
        if (ping === null) return 0; // Offline
        if (ping < 200) return 4; // Excellent
        if (ping < 450) return 3; // Good
        if (ping < 560) return 2; // Moderate
        if (ping < 700) return 1; // Poor
        return 0; // Very Poor
    };

    // Define colors based on ping
    const getColor = (strength) => {
        if (strength === 4) return 'green';       // Excellent
        if (strength === 3) return 'lightgreen';  // Good
        if (strength === 2) return 'yellow';       // Moderate
        if (strength === 1) return 'orange';       // Poor
        return 'lightgray';                         // Very Poor
    };

    // Get ping grading
    const getPingGrade = () => {
        if (ping === null) return 'Offline';
        if (ping < 200) return 'Excellent';
        if (ping < 450) return 'Good';
        if (ping < 560) return 'Moderate';
        if (ping < 700) return 'Poor';
        return 'Very Poor';
    };

    // Get signal strength based on ping
    const signalStrength = getSignalStrength();

    // Toggle visibility on click
    const handleToggle = () => {
        setIsVisible(!isVisible);
    };

    if (!isVisible) return null; // Hide the component if not visible

    return (
        <div
            onClick={handleToggle}
            style={{
                position: 'fixed',
                bottom: '10px',
                left: '10px',
                backgroundColor: 'black',
                padding: '10px',
                borderRadius: '5px',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column', // Stack signal strength and network type vertically
            }}
            onMouseEnter={() => setIsHovered(true)} // Show tooltip on hover
            onMouseLeave={() => setIsHovered(false)} // Hide tooltip on mouse leave
        >
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                {Array.from({ length: signalStrength }, (_, index) => (
                    <div
                        key={index}
                        style={{
                            width: '10px',
                            height: '20px', // Fixed height for bars
                            backgroundColor: getColor(signalStrength),
                            margin: '0 2px',
                        }}
                    />
                ))}
                {signalStrength === 0 && (
                    <div style={{ width: '10px', height: '20px', backgroundColor: 'red', margin: '0 2px' }} />
                )}
            </div>
            <div style={{ color: 'white', marginTop: '8px' }}>
                {networkType.toUpperCase()} {/* Display network type below the bars */}
            </div>
            {isHovered && (
                <div
                    style={{
                        position: 'absolute',
                        bottom: '60px', // Adjust position above the component
                        left: '10px',
                        backgroundColor: 'rgba(0, 0, 0, 0.8)',
                        color: 'white',
                        padding: '5px',
                        borderRadius: '5px',
                        zIndex: 1,
                    }}
                >
                    <div>Connection type: {networkType.toUpperCase()}</div>
                    <div>Speed: {ping !== null ? `${ping} ms` : 'Offline'}</div>
                    <div>Quality: {getPingGrade()}</div>
                </div>
            )}
        </div>
    );
}

export default NetworkStrength;

import React, { useState, useEffect } from 'react';
import { urls } from './config.dev';
import { toast } from 'react-toastify'; // Ensure you have this for toast notifications
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function RadiologyResultsModal5({ patient, onClose, clinicDetails }) {
    const [radiologyTests, setRadiologyTests] = useState([]); // Initialize as an empty array
    const [radiologyResults, setRadiologyResults] = useState([]); // Initialize as an empty array
    const [editedRadiologyResults, setEditedRadiologyResults] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [printLoading, setPrintLoading] = useState(false); // State for print button loading
    const [isChanged, setIsChanged] = useState(false); // Track changes for save button

    useEffect(() => {
        fetchRadiologyResults();
    }, []);

    const fetchRadiologyResults = () => {
        setLoading(true);
    
        fetch(urls.fetchradiologyresults, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ fileId: patient.file_id })
        })
        .then(response => {
            if (response.ok) {
                return response.json();
            } else {
                throw new Error('No radiology results found for the provided file ID');
            }
        })
        .then(data => {
            const exams = data.radiology_exams || []; // Change from radiology_tests to radiology_exams
            const results = data.radiology_results || []; // Keep this as is
    
            // Remove the prefix "Radiology Exam: " from the exams
            setRadiologyTests(exams.map(exam => exam.replace('Radiology Exam: ', '')));
            setRadiologyResults(results);
            setEditedRadiologyResults(results); // Initialize edited results
        })
        .catch(error => {
            setError(error.message);
        })
        .finally(() => {
            setLoading(false);
        });
    };
    

    const handleSaveRadiologyChanges = () => {
        const payload = {
            fileId: patient.file_id,
            newResults: editedRadiologyResults.join('\n'), // Join edited results with new lines
        };

        setLoading(true); // Set loading to true when starting the fetch

        fetch(urls.updateradiologyresults, { // Ensure you have this endpoint configured
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
        })
        .then(response => {
            if (response.ok) {
                toast.success('Radiology results updated successfully!', { autoClose: 3000 }); // Show success toast
                fetchRadiologyResults(); // Refresh the results
                setIsChanged(false); // Reset change tracking
            } else {
                throw new Error('Failed to update radiology results');
            }
        })
        .catch(error => {
            toast.error(error.message, { autoClose: 3000 }); // Show error toast
        })
        .finally(() => {
            setLoading(false); // Set loading to false after request completes
        });
    };

    const handlePrint = () => {
        setPrintLoading(true); // Set loading state to true
        const printData = {
            clinicName: clinicDetails?.clinic_name || 'No Clinic Name Available',
            contact: clinicDetails?.owners_contact || 'No Contact Available',
            location: `${clinicDetails?.sub_county || ''}, ${clinicDetails?.district || ''}`,
            patientName: `${patient.first_name} ${patient.last_name}`,
            patientAge: patient.age,
            patientSex: patient.sex,
            radiologyTests,
            radiologyResults
        };

        fetch(urls.pdfscan, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(printData)
        })
        .then(response => response.blob())
        .then(blob => {
            const url = window.URL.createObjectURL(blob);
            window.open(url);
        })
        .catch(error => {
            setError("Failed to generate PDF");
            console.error("PDF generation error:", error);
        })
        .finally(() => {
            setPrintLoading(false); // Reset loading state
        });
    };

    return (
        <div className="radiology-modal-overlay">
                <ToastContainer />
            <style>
                {`
                .radiology-modal-overlay {
                    position: fixed;
                    top: 0;
                    left: 0;
                    width: 100vw;
                    height: 100vh;
                    background: rgba(0, 0, 0, 0.5);
                    backdrop-filter: blur(8px);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    z-index: 1000;
                }
                .radiology-modal-content {
                    background: white;
                    width: 80%;
                    max-width: 1000px;
                    height: 80%;
                    max-height: 80vh;
                    overflow: auto;
                    padding: 20px;
                    padding-top: 60px; /* Add padding to create space for the close button */
                    box-sizing: border-box;
                    border: 1px solid green;
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                }
                .radiology-close-button {
                    position: absolute;
                    top: 10px;
                    right: 10px;
                    background: none;
                    border: none;
                    font-size: 18px;
                    cursor: pointer;
                    color: red;
                    z-index: 10; /* Ensure it stays above other content */
                }
                .radiology-header {
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-start;
                    margin-bottom: 20px;
                }
                .radiology-header h1 {
                    font-size: 24px;
                    margin: 0;
                    text-align: left;
                    color: black;
                }
                .radiology-header div {
                    text-align: right;
                    color: black;
                }
                .radiology-patient-details {
                    text-align: right;
                    margin-top: 20px;
                }
                .radiology-patient-details h3 {
                    text-transform: uppercase;
                }
                .radiology-results {
                    font-family: 'Times New Roman', Times, serif;
                    flex: 1;
                    overflow-y: auto;
                }
                .radiology-results h3, .radiology-results h4 {
                    font-weight: bold;
                    text-decoration: underline;
                    font-size: 25px;
                }
                .radiology-results pre, .radiology-results p {
                    font-family: 'Times New Roman', Times, serif;
                    white-space: pre-wrap;
                    word-wrap: break-word;
                    text-align: left;
                }
                .radiology-button-area {
                    margin-top: 20px;
                    display: flex;
                    justify-content: space-between;
                }
                .spinner {
                    width: 20px;
                    height: 20px;
                    border: 3px solid rgba(0, 0, 0, 0.2);
                    border-top: 3px solid #000;
                    border-radius: 50%;
                    animation: spin 1s linear infinite;
                }
                @keyframes spin {
                    0% { transform: rotate(0deg); }
                    100% { transform: rotate(360deg); }
                }
                @media print {
                    .radiology-modal-overlay {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        background: transparent;
                        backdrop-filter: none;
                        display: block;
                    }
                    .radiology-modal-content {
                        position: static;
                        width: 100%;
                        height: auto;
                        margin: 0;
                        border: none;
                        padding: 0;
                        page-break-inside: avoid;
                        box-sizing: border-box;
                    }
                    .radiology-modal-content * {
                        visibility: visible;
                    }
                }
                `}
            </style>
            <div className="radiology-modal-content">
                <button className="radiology-close-button" onClick={onClose}>X</button>
                <div className="radiology-header">
                    <h1>{clinicDetails?.clinic_name || 'No Clinic Name Available'}</h1>
                    <div>
                        <p>Phone: <br />{clinicDetails?.owners_contact || 'No Contact Available'}</p>
                        <p>{clinicDetails?.sub_county}, {clinicDetails?.district}</p>
                    </div>
                </div>

                <div className="radiology-patient-details">
                    <h3>Patient Details</h3>
                    <div>
                        <p>Name: {patient.first_name} {patient.last_name}</p>
                        <p>Age: {patient.age}</p>
                        <p>Sex: {patient.sex}</p>
                    </div>
                </div>

                {loading ? (
                    <div>Loading...</div>
                ) : (
                    <div className="radiology-results">
                        <h3>Radiology Results:</h3>
                        {error ? (
                            <p>{error}</p>
                        ) : (
                            radiologyTests.map((test, index) => (
                                <div key={index}>
                                    <h4>{test}</h4>
                                    <textarea
                                        value={editedRadiologyResults[index] || ''} // Ensure it defaults to an empty string
                                        onChange={(e) => {
                                            const newResults = [...editedRadiologyResults];
                                            newResults[index] = e.target.value;
                                            setEditedRadiologyResults(newResults);
                                            setIsChanged(true); // Mark as changed
                                        }}
                                        style={{
                                            width: '100%',
                                            marginBottom: '8px',
                                            height: '100px',
                                            resize: 'vertical',
                                            border: '1px solid #ccc',
                                            borderRadius: '4px',
                                            padding: '8px',
                                            fontSize: '16px'
                                        }}
                                    />
                                </div>
                            ))
                        )}
                    </div>
                )}

                <div className="radiology-button-area">
                    <button onClick={handleSaveRadiologyChanges} disabled={!isChanged || loading}>
                        {loading ? <div className="spinner" /> : 'Save Radiology Changes'}
                    </button>
                    <button onClick={handlePrint} disabled={printLoading}>
                        {printLoading ? <div className="spinner" /> : 'Print Radiology Results'}
                    </button>
                </div>
            </div>
        </div>
    );
}

export default RadiologyResultsModal5;

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { urls } from './config.dev';
import './LabDashboard.css';
import Topbar from './Topbar'; // Import the Topbar component
import Resultmodallab from './Resultmodallab'; // Import Resultmodal component

function LabDashboard() {
  const [pendingLabTestsCount, setPendingLabTestsCount] = useState(0);
  const [recentLabTests, setRecentLabTests] = useState([]); // Initialize with an empty array
  const [employeeName, setEmployeeName] = useState('');
  const [clinicDetails, setClinicDetails] = useState(null); // To store clinic details
  const [selectedPatient, setSelectedPatient] = useState(null); // To store the selected patient's details
  const [isLabResultsModalOpen, setIsLabResultsModalOpen] = useState(false); // Control modal visibility
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const urlToken = params.get('token'); // Define urlToken here

  useEffect(() => {
    const fetchTokenAndCheckSecurity = async () => {
      try {
        const tokenFromUrl = params.get('token');

        const securityResponse = await fetch(urls.security, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ token: tokenFromUrl }),
        });

        if (securityResponse.ok) {
          const securityData = await securityResponse.json();

          if (securityData.message === 'Session valid') {
            setEmployeeName(securityData.employee_name);
            fetchPendingLabTestsCount(tokenFromUrl); // Fetch pending lab tests count after security check
            fetchRecentLabTests(tokenFromUrl); // Fetch recent lab tests
            fetchClinicDetails(tokenFromUrl); // Fetch clinic details
          } else if (securityData.error === 'Session expired') {
            navigate(`/dashboard?token=${securityData.clinic_session_token}`);
          } else {
            navigate('/login');
          }
        } else {
          throw new Error('Failed to perform security check');
        }
      } catch (error) {
        console.error('Error performing security check:', error);
        navigate('/login');
      }
    };

    fetchTokenAndCheckSecurity();
  }, [navigate]);

  const fetchPendingLabTestsCount = async (token) => {
    try {
      const response = await fetch(urls.labtestscount, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token }),
      });

      if (response.ok) {
        const data = await response.json();
        setPendingLabTestsCount(data.total_pending); // Correct property name
      } else {
        throw new Error('Failed to fetch pending lab tests count');
      }
    } catch (error) {
      console.error('Error fetching pending lab tests count:', error);
    }
  };

  const fetchRecentLabTests = async (token) => {
    try {
      const response = await fetch(urls.alllab, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token }),
      });

      if (response.ok) {
        const data = await response.json();
        setRecentLabTests(data.patients || []); // Ensure patients array is defined, default to empty array
      } else {
        throw new Error('Failed to fetch recent lab tests');
      }
    } catch (error) {
      console.error('Error fetching recent lab tests:', error);
    }
  };

  const fetchClinicDetails = async (token) => {
    try {
      const response = await fetch(urls.fetchclinicdetails, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token }),
      });
      if (response.ok) {
        const data = await response.json();
        setClinicDetails(data); // Save clinic details
      } else {
        throw new Error('Failed to fetch clinic details');
      }
    } catch (error) {
      console.error('Error fetching clinic details:', error);
    }
  };

  const handleShowLabResults = (patient) => {
    setSelectedPatient({
      ...patient,
      clinicDetails, // Include clinic details
    });
    setIsLabResultsModalOpen(true); // Open the modal
  };

  const handleCloseModal = () => {
    setIsLabResultsModalOpen(false); // Close the modal
  };

  useEffect(() => {
    const tokenFromUrl = params.get('token');
    if (tokenFromUrl) {
      const interval = setInterval(() => fetchPendingLabTestsCount(tokenFromUrl), 10000); // Fetch every 10 seconds
      return () => clearInterval(interval); // Cleanup interval on component unmount
    }
  }, []);

  const handleGoToPendingLabTests = () => {
    const tokenFromUrl = params.get('token');
    navigate(`/lab?token=${tokenFromUrl}`);
  };

  return (
    <div className="lab-dashboard-container" style={{ marginTop: '80px' }}>
      <Topbar token={urlToken} />
  
      {/* Header for Recent Lab Tests */}
      <div className="header-container" style={{ animation: pendingLabTestsCount > 0 ? 'pulse 1.5s infinite' : 'none' }}>
        {pendingLabTestsCount > 0 ? (
          <div
            className={`pending-lab-tests ${pendingLabTestsCount > 0 ? 'active' : ''}`}
            onClick={handleGoToPendingLabTests}
            style={{
              padding: '15px',
              backgroundColor: '#ffffff', // Set background to white
              color: '#ff0000', // Set text color to red
              border: '1px solid #f5c6cb', // Change this to a red border if desired
              borderRadius: '5px',
              cursor: 'pointer',
              marginBottom: '20px',
              marginLeft: '20px', // Adjust the value as needed for spacing
              textAlign: 'center', // Center the text
            }}
          >
            Hello {employeeName}, you have {pendingLabTestsCount} patient{pendingLabTestsCount > 1 ? 's' : ''} waiting for lab services. Click here to see them.
          </div>
        ) : (
          <div style={{ padding: '15px', marginBottom: '20px' }}>
            Hello {employeeName}, there are no patients waiting for lab services at the moment.
          </div>
        )}
      </div>
  
      {/* Recent Lab Tests Section */}
      <h2 style={{ textAlign: 'left', fontSize: '30px', marginBottom: '20px' }}>
        Recent Lab Tests Done
      </h2>
      <div className="recent-lab-tests-container" style={{ display: 'flex', flexWrap: 'wrap', gap: '20px' }}>
        {recentLabTests.length === 0 ? (
          <p>No recent lab tests available.</p>
        ) : (
          recentLabTests.map((patient) => (
            <div
              key={patient.file_id}
              className="lab-test-card"
              style={{
                padding: '20px',
                border: '1px solid #ccc',
                borderRadius: '5px',
                boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                width: 'calc(25% - 20px)', // Four cards per row
                boxSizing: 'border-box',
                display: 'flex',
                flexDirection: 'column', // Ensure items are stacked vertically
                justifyContent: 'space-between', // Push button to the bottom
                textAlign: 'left',
              }}
            >
              {/* Patient Name */}
              <h3 style={{ color: 'black', fontWeight: 'bold', textAlign: 'center', textTransform: 'uppercase' }}>
                {patient.first_name} {patient.last_name}
              </h3>
  
              {/* Patient Details */}
              <div style={{ color: 'black', marginTop: '10px' }}>
                <p>Age: {patient.age}</p>
                <p>Sex: {patient.sex}</p>
                <p>Contact: {patient.phone_number ? patient.phone_number : 'N/A'}</p>
                <p>Address: {patient.address ? patient.address : 'N/A'}</p>
                <p>{patient.lab_test}</p>
              </div>
  
              {/* Edit Results Button */}
              <span
                className="view-edit-link"
                onClick={() => handleShowLabResults(patient)}
                style={{
                  backgroundColor: '#007bff',
                  color: 'white',
                  padding: '10px',
                  cursor: 'pointer',
                  textAlign: 'center',
                  borderRadius: '0px',
                  display: 'inline-block',
                  marginTop: 'auto', // Push the link to the bottom
                }}
              >
                Click here to edit results
              </span>
            </div>
          ))
        )}
      </div>
  
      {/* Lab Results Modal */}
      {isLabResultsModalOpen && (
        <Resultmodallab
          patient={selectedPatient}
          clinicDetails={clinicDetails}
          onClose={handleCloseModal}
        />
      )}
    </div>
  );
  
  
}

export default LabDashboard;

import React, { useState, useEffect } from 'react';
import { urls } from './config.dev';
import './AwaitingPayments.css';
import { useNavigate } from 'react-router-dom';
import Topbar from './Topbar'; // Import the Topbar component
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import InvestigationReceiptModal from './InvestigationReceiptModal'; // Import the receipt modal
import FPreceipt from './FPreceipt'; // Import the Family Planning Receipt component

function AwaitingPayments() {
  const [patients, setPatients] = useState([]);
  const [familyPlanningPatients, setFamilyPlanningPatients] = useState([]); // State for family planning patients
  const [loading, setLoading] = useState(true);
  const [confirmingPayment, setConfirmingPayment] = useState(false);
  const [employeeName, setEmployeeName] = useState('');
  const [showReceiptModal, setShowReceiptModal] = useState(false); // State for showing receipt modal
  const [receiptDetails, setReceiptDetails] = useState(null); // Store receipt details
  const [selectedPatient, setSelectedPatient] = useState(null); // Store the selected patient for receipt
  const [showFPReceiptModal, setShowFPReceiptModal] = useState(false); // State for showing FP receipt modal
  const [fpReceiptDetails, setFpReceiptDetails] = useState(null); // Store FP receipt details
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const urlToken = params.get('token'); // Define urlToken here



  useEffect(() => {
    const fetchTokenAndCheckSecurity = async () => {
      try {
        const params = new URLSearchParams(window.location.search);
        const tokenFromUrl = params.get('token');

        const securityResponse = await fetch(urls.security, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ token: tokenFromUrl }),
        });

        if (securityResponse.ok) {
          const securityData = await securityResponse.json();

          if (securityData.message === 'Session valid') {
            setEmployeeName(securityData.employee_name);

            // Fetch awaiting payments after security check
            fetchAwaitingPayments(tokenFromUrl);
            fetchFamilyPlanningPatients(tokenFromUrl); // Fetch family planning patients
            const intervalId = setInterval(() => {
              fetchAwaitingPayments(tokenFromUrl);
              fetchFamilyPlanningPatients(tokenFromUrl); // Fetch family planning patients
            }, 10000);

            return () => clearInterval(intervalId); // Clear interval on component unmount
          } else if (securityData.error === 'Session expired') {
            toast.warning('Session expired, redirecting to the dashboard...');
            navigate(`/dashboard?token=${securityData.clinic_session_token}`);
          } else {
            toast.error('Session invalid, redirecting to login...');
            navigate('/login');
          }
        } else {
          throw new Error('Failed to perform security check');
        }
      } catch (error) {
        console.error('Error performing security check:', error);
        toast.error('Error performing security check, redirecting to login...');
        navigate('/login');
      }
    };

    fetchTokenAndCheckSecurity();
  }, [navigate]);

  const fetchAwaitingPayments = async (token) => {
    try {
      const response = await fetch(urls.waitingpayment, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token }),
      });

      if (response.ok) {
        const data = await response.json();
        setPatients(data);
      } else {
        throw new Error('Failed to fetch awaiting payments');
      }
    } catch (error) {
      console.error('Error fetching awaiting payments:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchFamilyPlanningPatients = async (token) => {
    try {
      const response = await fetch(urls.awaitingpaymentsFP, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token }),
      });
  
      if (response.ok) {
        const data = await response.json();
        // Check if the response is an array before setting it to the state
        if (Array.isArray(data)) {
          setFamilyPlanningPatients(data); // Update state with family planning patients
        } else {
          setFamilyPlanningPatients([]); // Set an empty array if the response is not an array
        }
      } else {
        throw new Error('Failed to fetch family planning patients');
      }
    } catch (error) {
      console.error('Error fetching family planning patients:', error);
      setFamilyPlanningPatients([]); // Handle error by setting an empty array
    }
  };
  
  const handleConfirmPayment = async (patient) => {
    // Calculate total bill amount
    const { lab_tests, radiology_exams } = patient;
    let tests = [];
    if (lab_tests) tests = tests.concat(lab_tests);
    if (radiology_exams) tests = tests.concat(radiology_exams);
  
    const totalBill = tests.reduce((acc, test) => {
      const price = parseFloat(test.price);
      return isNaN(price) ? acc : acc + price;
    }, 0);
  
    // Show confirmation alert
    const confirmationMessage = `Do you confirm that you have received UGX ${totalBill} payment for investigations?`;
    if (!window.confirm(confirmationMessage)) {
      // User did not confirm; exit the function
      return;
    }
  
    try {
      setConfirmingPayment(true);
      setSelectedPatient(patient); // Set the selected patient for the receipt
  
      const payload = {
        ...patient,
        totalBill, // Include total amount in the payload
        token: urlToken,
      };
  
      const response = await fetch(urls.payinvestigations, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });
  
      if (response.ok) {
        toast.success('Payment successful');
        setReceiptDetails({ ...patient, tests, totalBill }); // Set receipt details
        setShowReceiptModal(true); // Show receipt modal
      } else {
        throw new Error('Failed to confirm payment');
      }
    } catch (error) {
      console.error('Error confirming payment:', error);
      toast.error('Failed to confirm payment. Please try again.');
    } finally {
      setConfirmingPayment(false);
    }
  };
  
  const handleFPConfirmPayment = async (patient) => {
    // Show confirmation alert
    const confirmationMessage = `You are about to confirm that you have received payment for Family Planning?`;
    if (!window.confirm(confirmationMessage)) {
      // User did not confirm; exit the function
      return;
    }
  
    try {
      setConfirmingPayment(true);
      setSelectedPatient(patient); // Set the selected patient for FP receipt
  
      const payload = {
        ...patient,
        token: urlToken,
      };
  
      const response = await fetch(urls.finishedpayingFP, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });
  
      if (response.ok) {
        toast.success('Family Planning Payment successful');
        setFpReceiptDetails({ ...patient, token: urlToken }); // Include the token in receipt details
        setShowFPReceiptModal(true); // Show FP receipt modal
      } else {
        throw new Error('Failed to confirm Family Planning payment');
      }
    } catch (error) {
      console.error('Error confirming Family Planning payment:', error);
      toast.error('Failed to confirm Family Planning payment. Please try again.');
    } finally {
      setConfirmingPayment(false);
    }
  };
  

  

  const handleCloseReceiptModal = () => {
    setShowReceiptModal(false); // Close receipt modal
  };

  const handleCloseFPReceiptModal = () => {
    setShowFPReceiptModal(false); // Close FP receipt modal
  };

  return (
    <div 
    className="awaiting-payments-container" 
    style={{ 
      paddingTop: '70px', 
      paddingBottom: '20px', 
      paddingLeft: '60px', 
      paddingRight: '60px', 
      width: '100%', 
      height: '100vh', // Ensure the height fills the entire screen
      boxSizing: 'border-box', 
      backgroundColor: 'black' // Set background color to black
    }}
    
    >
      <Topbar token={urlToken} />
      <ToastContainer />
      <div 
        className="awaiting-payments-content"
        style={{
          width: '100vw', // Fill full viewport width
          height: '100vh', // Fill full viewport height
          backgroundColor: '#fff',
          borderRadius: '0px',
          boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
          padding: '20px',
          overflowY: 'auto', // Enable vertical scrolling
        }}
      >
        <h2 
          className="awaiting-payments-title" 
          style={{ 
            textAlign: 'center', 
            marginBottom: '20px', 
            color: '#333' 
          }}
        >
          Patients Awaiting Payments for Laboratory or Radiology Services
        </h2>

        {loading ? (
          <div 
            className="awaiting-payments-loading" 
            style={{ 
              display: 'flex', 
              flexDirection: 'column', 
              alignItems: 'center' 
            }}
          >
            <div className="awaiting-payments-spinner" style={{ marginBottom: '10px' }}></div>
            <p>Loading...</p>
          </div>
        ) : patients.length === 0 ? (
          <p className="awaiting-payments-no-patients" style={{ textAlign: 'center', fontSize: '18px', color: '#555' }}>
            No patients awaiting payments.
          </p>
        ) : (
          <div className="awaiting-payments-list" style={{ 
            display: 'flex', 
            flexWrap: 'wrap', 
            justifyContent: 'space-between', 
            marginBottom: '20px' 
          }}>
            {patients.map((patient, index) => {
              const { patient_name, file_id, contact_id, lab_tests, radiology_exams } = patient;
              let tests = [];
              if (lab_tests) {
                tests = tests.concat(lab_tests);
              }
              if (radiology_exams) {
                tests = tests.concat(radiology_exams);
              }

              const totalBill = tests.reduce((acc, test) => {
                const price = parseFloat(test.price);
                return isNaN(price) ? acc : acc + price;
              }, 0);

              return (
                <div 
                  key={index} 
                  className="lab-payment-cards" 
                  style={{ 
                    border: '1px solid #e0e0e0', 
                    borderRadius: '8px', 
                    padding: '15px', 
                    margin: '10px', // Add margin for spacing
                    flex: '1 0 calc(25% - 20px)', // Flexbox: 25% width minus margin
                    boxSizing: 'border-box',
                    backgroundColor: '#f9f9f9' 
                  }}
                >
                  <div className="patient-details-payment">
                    <p><strong>Name:</strong> {patient_name}</p>
                    <p><strong>File ID:</strong> {file_id}</p>
                    <p><strong>Contact ID:</strong> {contact_id}</p>
                    <p><strong>Total Bill:</strong> UGX {totalBill.toFixed(2)}</p>
                  </div>
                  <button
                    className="confirm-payment-button-cashier" 
                    onClick={() => handleConfirmPayment(patient)} 
                    disabled={confirmingPayment} 
                    style={{ 
                      padding: '10px 15px', 
                      borderRadius: '4px', 
                      backgroundColor: '#007bff', 
                      color: '#fff', 
                      border: 'none', 
                      cursor: 'pointer', 
                      transition: 'background-color 0.3s' 
                    }}
                    onMouseOver={(e) => (e.currentTarget.style.backgroundColor = '#0056b3')}
                    onMouseOut={(e) => (e.currentTarget.style.backgroundColor = '#007bff')}
                  >
                    {confirmingPayment ? 'Confirming Payment...' : 'Confirm Payment'}
                  </button>
                </div>
              );
            })}
          </div>
        )}

        {familyPlanningPatients.length > 0 && (
          <>
            <h2 
              className="awaiting-payments-title" 
              style={{ 
                textAlign: 'center', 
                marginTop: '20px', 
                color: '#333' 
              }}
            >
              Family Planning Patients Awaiting Payments
            </h2>

            <div className="family-planning-list" style={{ 
              display: 'flex', 
              flexWrap: 'wrap', 
              justifyContent: 'space-between', 
              marginBottom: '20px' 
            }}>
              {familyPlanningPatients.map((patient, index) => (
                <div 
                  key={index} 
                  className="fp-patient-card" 
                  style={{ 
                    border: '1px solid #e0e0e0', 
                    borderRadius: '8px', 
                    padding: '15px', 
                    margin: '10px', // Add margin for spacing
                    flex: '1 0 calc(25% - 20px)', // Flexbox: 25% width minus margin
                    boxSizing: 'border-box',
                    backgroundColor: '#f9f9f9' 
                  }}
                >
                  <div className="fp-patient-details">
                    <p><strong>Name:</strong> {patient.patient_name}</p>
                    <p><strong>Family Planning Method:</strong> {patient.method}</p>
                    <p><strong>Bill:</strong> UGX {parseFloat(patient.price).toFixed(2)}</p>
                  </div>
                  <button
                    className="confirm-fp-payment-button" 
                    onClick={() => handleFPConfirmPayment(patient)} 
                    disabled={confirmingPayment} 
                    style={{ 
                      padding: '10px 15px', 
                      borderRadius: '4px', 
                      backgroundColor: '#28a745', 
                      color: '#fff', 
                      border: 'none', 
                      cursor: 'pointer', 
                      transition: 'background-color 0.3s' 
                    }}
                    onMouseOver={(e) => (e.currentTarget.style.backgroundColor = '#218838')}
                    onMouseOut={(e) => (e.currentTarget.style.backgroundColor = '#28a745')}
                  >
                    {confirmingPayment ? 'Confirming Payment...' : 'Confirm Family Planning Payment'}
                  </button>
                </div>
              ))}
            </div>
          </>
        )}
      </div>

      {showReceiptModal && (
        <InvestigationReceiptModal
          isOpen={showReceiptModal}
          onClose={handleCloseReceiptModal}
          details={receiptDetails}
          token={urlToken} // Pass the token as a prop
        />
      )}
    
      {showFPReceiptModal && (
        <FPreceipt
          details={fpReceiptDetails}
          onClose={handleCloseFPReceiptModal}
        />
      )}
    </div>
  );
}

export default AwaitingPayments;

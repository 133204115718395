import React, { useState } from 'react';
import './RadiologyResultModal.css';
import { urls } from './config.dev';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ThirdTrimesterModal from './ThirdTrimesterModal';
import FirstTrimesterModal from './FirstTrimesterModal'; // Import FirstTrimesterModal
import SecondTrimesterModal from './SecondTrimesterModal'; // Import SecondTrimesterModal

function RadiologyResultModal({ patient, onClose, clinicDetails, token, totalRadiologyExams }) {
  const [results, setResults] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [printLoading, setPrintLoading] = useState(false);
  const [error, setError] = useState('');
  const [formatting, setFormatting] = useState({
    bold: false,
    italic: false,
    underlined: false,
    fontSize: '14px', // Default font size
  });
  const [showThirdTrimesterModal, setShowThirdTrimesterModal] = useState(false);
  const [showFirstTrimesterModal, setShowFirstTrimesterModal] = useState(false); // State for First Trimester modal
  const [showSecondTrimesterModal, setShowSecondTrimesterModal] = useState(false); // State for Second Trimester modal

  const handleInputChange = (event) => {
    setResults(event.target.value);
  };

  const toggleFormatting = (format) => {
    setFormatting((prevFormatting) => ({
      ...prevFormatting,
      [format]: !prevFormatting[format],
    }));
  };

  const handleFontSizeChange = (event) => {
    setFormatting((prevFormatting) => ({
      ...prevFormatting,
      fontSize: event.target.value,
    }));
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      const requestBody = {
        file_id: patient.file_id,
        contact_id: patient.contact_id,
        results,
        formatting,
        totalRadiologyExams,
        token, // Include token in the request body
      };

      const response = await fetch(urls.submitradiologyresults, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });

      if (response.ok) {
        toast.success('Radiology results submitted successfully!');
        // Ask user if they want to print the report
        if (window.confirm('Do you want to print a report?')) {
          handlePrint();
        } else {
          onClose();
        }
      } else {
        throw new Error('Failed to submit radiology results');
      }
    } catch (error) {
      console.error('Error submitting radiology results:', error);
      toast.error('Error submitting radiology results');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handlePrint = () => {
    setPrintLoading(true);

    // Prepare the data to be sent to the backend
    const printData = {
      clinicName: clinicDetails?.clinic_name || 'No Clinic Name Available',
      contact: clinicDetails?.owners_contact || 'No Contact Available',
      location: `${clinicDetails?.sub_county || ''}, ${clinicDetails?.district || ''}`,
      patientName: `${patient.first_name} ${patient.last_name}`,
      patientAge: patient.age,
      patientSex: patient.sex,
      radiologyTests: patient.radiology_exam || [], // Ensure radiologyTests is an array
      radiologyResults: results.trim().split('\n').map(result => result.trim()), // Format results
    };

    // Send data to the backend to generate the PDF
    fetch(urls.pdfscan2, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(printData)
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(`Failed to generate PDF: ${response.statusText}`);
        }
        return response.blob();
      })
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        window.open(url, '_blank'); // Open PDF in a new tab
        toast.success('PDF report generated successfully!');
      })
      .catch(error => {
        setError("Failed to generate PDF");
        console.error("PDF generation error:", error);
        toast.error('Failed to generate PDF report');
      })
      .finally(() => {
        setPrintLoading(false);
      });
  };

  const handleObstetricReport = () => {
    const trimester = window.prompt('Choose Trimester:\n1. Early First Trimester (4 to 7 weeks) \n2. Late First Trimester and Early Second Trimester(8 weeks to about 14 weeks)\n3. Late 2nd Trimester and  Third Trimester (about 18 weeks to 40weeks)');
    if (trimester === '1') {
      setShowFirstTrimesterModal(true); // Open the First Trimester modal
    } else if (trimester === '2') {
      setShowSecondTrimesterModal(true); // Open the Second Trimester modal
    } else if (trimester === '3') {
      setShowThirdTrimesterModal(true); // Open the Third Trimester modal
    } else {
      toast.warning('This feature is not implemented for the selected trimester.');
    }
  };

  return (
    <div
      className="radiology-modal-overlay"
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.6)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1000,
      }}
    >
      <div
        className="radiology-modal-content"
        style={{
          backgroundColor: "#fff",
          padding: "20px",
          borderRadius: "8px",
          width: "600px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
          overflowY: "auto",
        }}
      >
        <div className="radiology-header" style={{ textAlign: "center" }}>
          <h1 style={{ fontSize: "24px", fontWeight: "bold", color: "#333" }}>
            {clinicDetails.clinic_name}
          </h1>
          <p style={{ fontSize: "16px", color: "#777" }}>
            {clinicDetails.sub_county}
          </p>
          <h2 style={{ fontSize: "20px", color: "#444" }}>Radiology Results</h2>
          <h3 style={{ fontSize: "18px", color: "#555" }}>
            {`${patient.first_name} ${patient.last_name}`}
          </h3>
        </div>
  
        <div className="radiology-patient-details" style={{ marginTop: "20px" }}>
          <h3 style={{ fontSize: "18px", fontWeight: "bold" }}>Patient Details</h3>
          <p style={{ fontSize: "16px" }}>
            <strong>Name:</strong> {`${patient.first_name} ${patient.last_name}`}
          </p>
          <p style={{ fontSize: "16px" }}>
            <strong>Age:</strong> {patient.age}
          </p>
          <p style={{ fontSize: "16px" }}>
            <strong>Sex:</strong> {patient.sex}
          </p>
        </div>
  
       
        <div className="radiology-input-area" style={{ marginTop: "20px" }}>
          <h3 style={{ fontSize: "18px", fontWeight: "bold" }}>Enter Radiology Results</h3>
          <textarea
            value={results}
            onChange={handleInputChange}
            placeholder="Type your results here..."
            className={`radiology-results-input ${
              formatting.bold ? "radiology-bold" : ""
            } ${formatting.italic ? "radiology-italic" : ""} ${
              formatting.underlined ? "radiology-underlined" : ""
            }`}
            style={{
              width: "100%",
              height: "150px",
              padding: "10px",
              fontSize: formatting.fontSize || "14px",
              fontFamily: "Arial, sans-serif",
              borderRadius: "5px",
              border: "1px solid #ccc",
              outline: "none",
            }}
          />
        </div>
  
        <div className="radiology-button-area" style={{ marginTop: "20px", textAlign: "right" }}>
          <button
            onClick={handleSubmit}
            disabled={isSubmitting}
            style={{
              padding: "10px 20px",
              backgroundColor: "#007BFF",
              border: "none",
              borderRadius: "5px",
              color: "#fff",
              cursor: isSubmitting ? "not-allowed" : "pointer",
              marginRight: "10px",
            }}
          >
            {isSubmitting ? "Please wait..." : "Submit Report"}
          </button>
          <button
            onClick={onClose}
            disabled={isSubmitting}
            style={{
              padding: "10px 20px",
              backgroundColor: "#dc3545",
              border: "none",
              borderRadius: "5px",
              color: "#fff",
              cursor: isSubmitting ? "not-allowed" : "pointer",
            }}
          >
            Cancel
          </button>
          {error && (
            <p style={{ color: "red", marginTop: "10px", textAlign: "center" }}>
              {error}
            </p>
          )}
          <button
            onClick={handleObstetricReport}
            style={{
              padding: "10px 20px",
              backgroundColor: "#007BFF",
              border: "none",
              borderRadius: "5px",
              color: "#fff",
              marginTop: "10px",
              cursor: "pointer",
            }}
          >
            Obstetric Report Generator
          </button>
        </div>
  
        {showFirstTrimesterModal && (
          <FirstTrimesterModal
            patient={patient}
            onClose={() => setShowFirstTrimesterModal(false)}
            clinicDetails={clinicDetails}
            token={token}
            totalRadiologyExams={totalRadiologyExams}
          />
        )}
        {showSecondTrimesterModal && (
          <SecondTrimesterModal
            patient={patient}
            onClose={() => setShowSecondTrimesterModal(false)}
            clinicDetails={clinicDetails}
            token={token}
            totalRadiologyExams={totalRadiologyExams}
          />
        )}
        {showThirdTrimesterModal && (
          <ThirdTrimesterModal
            patient={patient}
            onClose={() => setShowThirdTrimesterModal(false)}
            clinicDetails={clinicDetails}
            token={token}
            totalRadiologyExams={totalRadiologyExams}
          />
        )}
      </div>
      <ToastContainer />
    </div>
  );
  
}

export default RadiologyResultModal;

import React, { useState, useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify'; // Import toast and ToastContainer
import 'react-toastify/dist/ReactToastify.css'; // Import the toast styles
import { urls } from './config.dev';

function Resultmodallab({ patient, onClose, clinicDetails }) {
    const [labTests, setLabTests] = useState([]);
    const [labResults, setLabResults] = useState([]);
    const [formatting, setFormatting] = useState({});
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [editedLabResults, setEditedLabResults] = useState([]);
    const [isChanged, setIsChanged] = useState(false);
    const [isLoading, setIsLoading] = useState(false); // Add loading state
    
    useEffect(() => {
        fetchLabResults();
    }, []);

    const fetchLabResults = () => {
        setLoading(true);

        fetch(urls.fetchlabresults, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ fileId: patient.file_id })
        })
        .then(response => {
            if (response.ok) {
                return response.json();
            } else {
                throw new Error('No laboratory results found for the provided file ID');
            }
        })
        .then(data => {
            setLabTests(data.lab_tests.map(test => test.replace('Lab Test: ', '')));
            setLabResults(data.lab_results);
            setEditedLabResults(data.lab_results); // Initialize editedLabResults
            setFormatting(data.formatting);
        })
        
        .catch(error => {
            setError(error.message);
        })
        .finally(() => {
            setLoading(false);
        });
    };

    const renderFormattedText = (text, index) => {
        const style = {
            fontWeight: formatting[index]?.bold ? 'bold' : 'normal',
            fontStyle: formatting[index]?.italic ? 'italic' : 'normal',
            fontSize: formatting[index]?.fontSize || 'inherit',
            textDecoration: formatting[index]?.underlined ? 'underline' : 'none',
            textAlign: /^\d/.test(text) ? 'right' : 'left',
        };

        const paragraphs = text.split('\n').map((paragraph, i) => (
            <p key={i} style={style}>{paragraph}</p>
        ));

        return <div>{paragraphs}</div>;
    };

    const handlePrint = () => {
        fetch(urls.pdflab, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                labTests,
                labResults,
                formatting,
                clinicDetails,
                patient
            })
        })
        .then(response => response.blob())
        .then(blob => {
            const url = URL.createObjectURL(blob);
            window.open(url, '_blank');
        })
        .catch(error => {
            console.error('Error generating PDF:', error);
        });
    };
    const handleSaveChanges = () => {
        const payload = {
            fileId: patient.file_id,
            newResults: editedLabResults.join('\n'), // Join with new lines
        };

        setIsLoading(true); // Set loading to true when starting the fetch

        fetch(urls.updatelabresults, { // Ensure you have this endpoint configured
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
        })
        .then(response => {
            if (response.ok) {
                toast.success('Lab results updated successfully!', { autoClose: 3000 }); // Show success toast
                setIsChanged(false); // Reset the change tracker
                fetchLabResults(); // Refresh the results if needed
            } else {
                throw new Error('Failed to update lab results');
            }
        })
        .catch(error => {
            toast.error(error.message, { autoClose: 3000 }); // Show error toast
        })
        .finally(() => {
            setIsLoading(false); // Set loading to false after request completes
        });
    };

    

    return (
        <div className="custom-modal-overlay">
            <ToastContainer position="top-right" autoClose={3000} hideProgressBar closeOnClick draggable pauseOnHover /> {/* ToastContainer */}
            <style></style>
            <style>
                {`
                body, html {
                    margin: 0;
                    padding: 0;
                    height: 100%;
                    overflow: hidden;
                }

                .custom-modal-overlay {
                    position: fixed;
                    top: 0;
                    left: 0;
                    width: 100vw;
                    height: 100vh;
                    background: rgba(0, 0, 0, 0.5);
                    backdrop-filter: blur(8px);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    z-index: 1000;
                }

                .custom-modal-content {
                    background: white;
                    width: 80%;
                    max-width: 1000px;
                    height: 80%;
                    max-height: 80vh;
                    overflow: auto;
                    padding: 20px;
                    box-sizing: border-box;
                    border: 1px solid green;
                    position: relative;
                    margin-top: 40px; /* Adds space at the top */
                }

                .custom-close-btn {
                    position: absolute;
                    top: 10px;
                    right: 10px;
                    font-size: 24px;
                    color: red;
                    cursor: pointer;
                    background: none;
                    border: none;
                }

                .custom-modal-header-spacing {
                    margin-top: 40px; /* Additional spacing for content */
                }

                @media print {
                    body {
                        margin: 0;
                        padding: 0;
                        overflow: hidden;
                        height: 100%;
                    }

                    .custom-modal-overlay {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        background: transparent;
                        backdrop-filter: none;
                        display: block;
                    }

                    .custom-modal-content {
                        position: static;
                        width: 100%;
                        height: auto;
                        margin: 0;
                        border: none;
                        padding: 0;
                        page-break-inside: avoid;
                        box-sizing: border-box;
                    }

                    .custom-modal-content * {
                        visibility: visible;
                    }
                }

                .custom-left-align {
                    text-align: left;
                }

                .custom-results {
                    font-family: 'Times New Roman', Times, serif;
                }

                .custom-button-area {
                    margin-top: 20px;
                    display: flex;
                    justify-content: space-between;
                }

                .custom-header {
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-start;
                    padding-bottom: 10px;
                    border-bottom: 1px solid #ddd;
                    margin-bottom: 20px;
                }

                .custom-header h1 {
                    font-size: 24px;
                    color: black;
                    margin: 0;
                }

                .custom-patient-details {
                    text-align: right;
                    margin-top: 20px;
                }

                .custom-patient-details h3 {
                    text-transform: uppercase;
                    margin: 0;
                    padding: 10px 0;
                }
                `}
            </style>
            <div id="custom-modal-content" className="custom-modal-content">
                <button className="custom-close-btn" onClick={onClose}>×</button>
                <div className="custom-modal-header-spacing">
                    <div className="custom-header">
                        <h1>{clinicDetails.clinic_name || 'No Clinic Name Available'}</h1>
                        <div style={{ textAlign: 'right' }}>
                            <p>Phone: <br />{clinicDetails.owners_contact || 'No Contact Available'}</p>
                            <p>{clinicDetails.sub_county || ''}, {clinicDetails.district || ''}</p>
                        </div>
                    </div>

                    <div className="custom-patient-details">
                        <h3>Patient Details</h3>
                        <div>
                        <p><strong>Name:</strong> {`${patient.first_name} ${patient.last_name}`}</p>

                            <p><strong>Age:</strong> {patient.age}</p>
                            <p><strong>Sex:</strong> {patient.sex}</p>
                        </div>
                    </div>

                    <div className="custom-results">
                        <div className="custom-lab-tests">
                            <h3>LABORATORY TESTS DONE</h3>
                            {loading ? (
                                <p>Fetching laboratory results, please wait...</p>
                            ) : error ? (
                                <p>{error}</p>
                            ) : (
                                <div>
                                    {labTests.map((test, index) => (
                                        renderFormattedText(test, index)
                                    ))}
                                </div>
                            )}
                        </div>
                        <div className="custom-lab-results">
    <h3>LABORATORY RESULTS:</h3>
    {loading ? (
        <p>Fetching laboratory results, please wait...</p>
    ) : error ? (
        <p>{error}</p>
    ) : (
        <div>
            {editedLabResults.map((result, index) => (
                <textarea
                key={index}
                value={result || ''}
                onChange={(e) => {
                    const newResults = [...editedLabResults];
                    newResults[index] = e.target.value;
                    setEditedLabResults(newResults);
                    setIsChanged(true); // Mark as changed
                }}
                style={{
                    fontWeight: formatting[index]?.bold ? 'bold' : 'normal',
                    fontStyle: formatting[index]?.italic ? 'italic' : 'normal',
                    fontSize: formatting[index]?.fontSize || 'inherit',
                    textDecoration: formatting[index]?.underlined ? 'underline' : 'none',
                    textAlign: /^\d/.test(result) ? 'right' : 'left',
                    width: '100%',
                    marginBottom: '8px',
                    height: '100px',
                    resize: 'vertical'
                }}
            />
            ))}
        </div>
    )}
</div>


                    </div>
                    <div className="custom-button-area">
    <button onClick={handlePrint}>Print</button>
    <button onClick={onClose}>Close</button>
    <button onClick={handleSaveChanges} disabled={!isChanged || isLoading}>
            {isLoading ? (
                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> // Spinner for loading
            ) : (
                'Make Changes'
            )}
        </button>
</div>

                </div>
            </div>
        </div>
    );
}

export default Resultmodallab;
